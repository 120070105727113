.slider__container {
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.lottery__slider-container {
    display: flex;
    max-width: 1000px;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    position: relative;
    text-align: center;
    justify-content: center;
}

.lottery {
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.lottery__ImgContainer {
    display: flex;
    justify-content: space-between;
    padding: 0;
    list-style-type: none;
    width: 100%;
}

.lottery__ImgItem {
    width: 200px;
    height: 300px;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    margin-right: 20px;  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
    justify-content: space-between;
    cursor: pointer;
}

.lotterySection__text-container {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

.lotterySection__text {
    color: black;
    margin: 0;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: start;
}

.lotterySection__text:last-child {
    margin-bottom: 0;
}  

.lotterySection__title_of_text {
    color: #555;
    font-size: 12px;
    text-align: start;
    margin: 0;
    margin-left: 10px;
}

.lotterySection__Img {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 10px;
}

.lottery-btn {
    width: 62px;
    height: 62px;
    border-radius: 50px;
    background-color: #D9D9D9;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lottery-btn:hover {
    background-color: #555;
}

.lotterySection__dots {
    display: flex;
    flex-wrap: wrap;
    max-width: 310px;
    justify-content: center;
    margin-top: 50px;
}

.lotterySection__dot {
    width: 12px;
    height: 12px;
    margin: 5px;
    background-color: #D9D9D9;
    border: 1px solid #D9D9D9;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.lotterySection__dot.active {
    background-color: #555;
}

.lotterySection_link {
    margin-top: 0;
    margin-bottom: 0;
    text-decoration: none;
    color: black;
    opacity: 0.7;
}

.lotterySection_link:hover {
    opacity: 1;
}

.lottery-btn-left {
    background-image: url(../../../../../images/arrowinslider.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 29px;
    transform: rotate(180deg);
}

.lottery-btn-right {
    background-image: url(../../../../../images/arrowinslider.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 29px;
}


@media screen and (max-width: 840px) {
    .lottery__ImgItem {
        display: flex; 
        margin: auto; 
    }    

    .lottery-btn-left {
        width: 20px;
        height: 20px;
    }
    
    .lottery-btn-right {
        width: 20px;
        height: 20px;
    }

    .lottery-btn {
        width: 50px;
        height: 50px;
    }

    .lotterySection__dots {
        margin-top: 25px;
    }

    .lotterySection_link {
        font-size: 14px;
    }
}