.aboutFestivalHaifa__main-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 160px);
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
    position: relative;
    background-color: #fce2c7;
}

@media screen and (max-width: 1065px) {
    .aboutFestivalHaifa__main-container {
        padding-left: 12px;
        padding-right: 12px;
        width: calc(100% - 24px);
    }
}

@media screen and (max-width: 770px) {
    .aboutFestivalHaifa__main-container {
        padding-top: 40px;
    }
}

.aboutFestivalHaifa__main-title {
    font-weight: bold;
    font-size: 32px;
    text-align: start;
    margin-top: 0;
    
    margin-bottom: 80px;
}

@media screen and (max-width: 1065px) {
    .aboutFestivalHaifa__main-title {
        font-size: 32px;
        
    }
}

@media screen and (max-width: 770px) {
    .aboutFestivalHaifa__main-title {
        margin-bottom: 40px;
    }
}

.aboutFestivalHaifa__text-wrapper {
    display: flex;
    padding: 0;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.aboutFestivalHaifa__text {
    color: #fff;
    font-size: 16px;
    
}

@media screen and (max-width: 1000px) {
    .aboutFestivalHaifa__text {
        font-size: 14px;
    }
}

.aboutFestivalHaifa__text-container {
    border-radius: 50%;
    background-color: #2ab09e;
    box-shadow: inset 0 0 10px white;
    width: 360px;
    height: 360px;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    margin: 5px;
}

@media screen and (max-width: 1000px) {
    .aboutFestivalHaifa__text-container {
        width: 300px;
        height: 300px;
    }
}

@media screen and (max-width: 350px) {
    .aboutFestivalHaifa__text-container {
        width: 280px;
        height: 280px;
    }
}

.aboutFestivalHaifa__title-container {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.aboutFestivalHaifa__sub-title { 
  text-align: center;
  color: #2ab09e;
  text-shadow: 2px 2px 4px rgba(223, 144, 25, 0.2);
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 20px;
}

.aboutFestivalHaifa__sub-title:last-of-type {
    margin-bottom: 80px;
}

@media screen and (max-width: 700px) {
    .aboutFestivalHaifa__sub-title:last-of-type {
        margin-bottom: 40px;
    }
}

.aboutFestivalHaifa__sub-title:first-of-type {
    margin-top: 0;
}

.aboutFestivalHaifa__sub-title_bigger {
    font-size: 50px;
}

@media screen and (max-width: 800px) {
    .aboutFestivalHaifa__sub-title_bigger {
        font-size: 32px;
    }
}

@media screen and (max-width: 342px) {
    .aboutFestivalHaifa__sub-title_bigger {
        font-size: 28px;
    }
}

.aboutFestivalHaifa__text-promotion {
    color: black;
    margin-bottom: 80px;
    margin-top: 80px;
}