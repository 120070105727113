.organizers__section {
    background-color: #fff;
    width: calc(100% - 86px);
    padding-left: 43px;
    padding-right: 43px;
    padding-bottom: 100px;
    padding-top: 300px;
    position: relative;
}

@media screen and (max-width: 1650px) {
    .organizers__section {
        padding-top: 200px;
    }
}

@media screen and (max-width: 1000px) {
    .organizers__section {
        padding-top: 150px;
    }
}

@media screen and (max-width: 635px) {
    .organizers__section {
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 25px;
    }
}

@media screen and (max-width: 500px) {
    .organizers__section {
        padding-top: 100px;
    }
}

.organizers__page-pic {
    background-image: url(../../../images/storytime/bookpagegrey.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-width: 100%;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1650px) {
    .organizers__page-pic  {
        height: 200px;
    }
}

@media screen and (max-width: 1000px) {
    .organizers__page-pic  {
        height: 150px;
    }
}

@media screen and (max-width: 500px) {
    .organizers__page-pic  {
        height: 100px;
    }
}

.organizers__title {
    color: #374BA5;
    text-align: start;
    font-size: 42px;
    font-family: 'Karton', sans-serif;
}

@media screen and (max-width: 900px) {
    .organizers__title  {
        font-size: 34px;
    }
}

@media screen and (max-width: 500px) {
    .organizers__title  {
        font-size: 24px;
    }
}

.organizers__ul-wrapper {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
    align-items: center;
}
.organizers__li-container {
    width: 30%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
    
.organizers__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.organizers__li-containerlogoNadav {
    margin-left: 10px;
}

@media screen and (max-width: 500px) {
    .organizers__li-containerlogoNadav  {
        max-height: 100px;
    }
}
