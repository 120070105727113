.separator_page_container {
    width: 100%;
    height: fit-content;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
} 

.separator_page_img {
    background-image: url(../../images/storytime/bookpagegrey.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-width: 100%;
    height: 300px;
}

@media screen and (max-width: 1650px) {
    .separator_page_img  {
        height: 200px;
    }
}

@media screen and (max-width: 1000px) {
    .separator_page_img  {
        height: 150px;
    }
}

@media screen and (max-width: 500px) {
    .separator_page_img  {
        height: 100px;
    }
}