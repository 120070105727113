

@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Neucha&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Neucha&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Martel:wght@200;300;400;600;700;800;900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

@font-face {
    font-family: 'Karton'; /* Имя вашего шрифта */
    src: url('./Karton.otf') format('opentype'); /* Путь к файлу шрифта */
    font-weight: normal; /* Укажите нужный вес */
    font-style: normal; /* Укажите стиль, если нужно */
}
