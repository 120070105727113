.purim2025__section {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.purim2025__pic-container {
    min-width: 100%;
    height: fit-content;
    margin: 0;
    padding: 0;
    background-image: url(../../../../images/banners/purim2025/background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    position: relative;
}

@media screen and (max-width: 883px) {
    .purim2025__pic-container {
        height: 451px;
        object-fit: cover;
    }
}

@media screen and (max-width: 400px) {
    .purim2025__pic-container {
        height: 311px;
    }
}

.purim2025__leftsq {
    background-image: url(../../../../images/banners/purim2025/left.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 290px;
    height: 340px;
    margin-top: 5px;
    margin-left: 5px;
}

.purim2025__text {
    background-image: url(../../../../images/banners/purim2025/text.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 366px;
    height: 366px;


    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    
}
.purim2025__rightsq {
    background-image: url(../../../../images/banners/purim2025/right.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 392px;
    height: 439px;
    margin: 5px;
}
/*
@media screen and (max-width: 1280px) {
    .purim2025__rightsq { 
        margin-top: 0px;
    }
    .purim2025__lefttsq { 
        margin-top: 0px;
    }
}


transform: rotate(180deg);
@media screen and (max-width: 400px) {
    .hanukaTelAviv2024Banner__pic-container {
        height: 451px;
    }
}*/
/*
.purim2025__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: flex;
    
}

@media screen and (max-width: 883px) {
    .purim2025__img {
        object-fit: cover;
    }
}*/

@media screen and (max-width: 400px) {
    .purim2025__pic-container {
        object-fit: contain;
    }
}

.purim2025__nav-container {
    background-color: #FCBB01;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 43px;
    padding-right: 43px;
    height: 85px;
}

@media screen and (max-width: 883px) {
    .hanukaTelAviv2024Banner__nav-container {
        padding-left: 0;
        padding-right: 0;
        padding: 0;
        flex-direction: column;
        height: fit-content;
    }
}


.hanukaTelAviv2024Banner__ul-container {
    display: flex;
    padding: 0;
    list-style-type: none;
    align-items: center;

}

@media screen and (max-width: 883px) {
    .hanukaTelAviv2024Banner__ul-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        width: 100%;
        margin: 0;
    }
}

.hanukaTelAviv2024Banner__link {
    text-decoration: none;
    color: #fff;
}

.hanukaTelAviv2024Banner__li {
    background-color: #374BA5;
    color: #fff;
    padding: 6px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 12px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    cursor: pointer;
    font-family: 'Karton', sans-serif;
    text-decoration: none;
}

.hanukaTelAviv2024Banner__li:hover {
    background-color: #F96A00;
}

@media screen and (max-width: 883px) {
    .hanukaTelAviv2024Banner__li {
        margin-right: 0;
        border-radius: 0;
        background-color: #FBBB00;
        color: #374BA5;
        font-weight: 600;
        border: 1px solid #fff;
        margin: 0;
        padding: 20px;
    }
}

@media screen and (max-width: 385px) {
    .hanukaTelAviv2024Banner__li {
        font-size: 12px;
        padding: 15px;
    }
}

.hanukaTelAviv2024Banner__link-btn {
    background-color: #F96A00;
    color: #fff;
    height: 43px;
    min-width: 145px;
    align-self: center;
    border-radius: 12px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    font-family: 'Karton', sans-serif;
    text-decoration: none;
    position: relative;
    z-index: 3;    
}

.hanukaTelAviv2024Banner__link-btn:hover {
    background-color: #374BA5;
}

@media screen and (max-width: 883px) {
    .hanukaTelAviv2024Banner__link-btn {
        width: 100%;
        margin: 0;
        padding: 20px;
        border-radius: 0;
        height: fit-content;
        background-color: #374BA5;
    }
}

@media screen and (max-width: 883px) {
    .hanukaTelAviv2024Banner__link-btn:hover {
        background-color: #F96A00;
    }
}


@media screen and (max-width: 890px) {
    .purim2025__leftsq {
        display: none;
    }

    .purim2025__pic-container {
       justify-content: end;
       align-items: flex-end;
    }

    .purim2025__rightsq {
        background-image: url(../../../../images/banners/purim2025/rightsmall.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 244px;
        height: 440px;
        margin: 5px;
    }
}

@media screen and (max-width: 675px) {
    .purim2025__rightsq {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .purim2025__text {
        width: 273px;
        height: 208px;
        display: flex;
        align-self: center;
        margin: auto;
    }
}