.layout {
    background-color:#F5F5F5;
    background-color:#fff;
    /*width: calc(100% - 70px);
    
    padding-left: 35px;
    padding-right: 35px;*/


    width: calc(100% - 86px);
    padding-left: 43px;
    padding-right: 43px;
    padding-bottom: 100px;
    padding-top: 75px;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .layout {
        padding-top: 50px;
    }
}

@media screen and (max-width: 635px) {
    .layout {
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 25px;
    }
}