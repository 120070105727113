.aboutFestival__main-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
}

@media screen and (max-width: 1065px) {
    .aboutFestival__main-container {
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media screen and (max-width: 770px) {
    .aboutFestival__main-container {
        padding-top: 40px;
    }
}

.aboutFestival__main-title {
    font-weight: bold;
    font-size: 32px;
    text-align: start;
    margin-top: 0;
    
    margin-bottom: 80px;
}

@media screen and (max-width: 1065px) {
    .aboutFestival__main-title {
        font-size: 32px;
        
    }
}

@media screen and (max-width: 770px) {
    .aboutFestival__main-title {
        margin-bottom: 40px;
    }
}

.aboutFestival__text-container {
    max-width: 100%;
    margin: auto;
}

/*first container*/

.aboutFestival__text-and-circle-wrapper {
    display: flex;
    position: relative;
    z-index: 10;
}

@media screen and (max-width: 700px) {
    .aboutFestival__text-and-circle-wrapper {
        flex-direction: column;
    }
}

.aboutFestival__blue-circle {
    background-image: url(../../../../images/aboutFesImages/bluecircle.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 835px;
    height: 835px;
    position: absolute;
    top: 200px;
    right: -200px;
    z-index: 0;
}

@media screen and (max-width: 700px) {
    .aboutFestival__blue-circle {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        top: 100px;
        right: -100px;
    }
}

.aboutFestival__firstPic-img {
    width: 70%;
    z-index: 10;
    margin-bottom: 100px;
    margin-right: 20px;
}

@media screen and (max-width: 700px) {
    .aboutFestival__firstPic-img {
        margin-bottom: 20px;
        width: 100%;
    }
}


.aboutFestival__firstPic-text {
    width: 30%;
    z-index: 10;
    text-align: start;
    margin: 0;
}

@media screen and (max-width: 700px) {
    .aboutFestival__firstPic-text {
        margin-bottom: 30px;
        width: 100%;
    }
}

/*second container*/

.aboutFestival__secondPic-container {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
}

.aboutFestival__secondPic-img {
    width: 100%;
    z-index: 10;
    margin-bottom: 50px;
    align-self: flex-start;
}

@media screen and (max-width: 700px) {
    .aboutFestival__secondPic-img {
        margin-bottom: 20px;
    }
}

.aboutFestival__secondPic-text {
    max-width: 834px;
    text-align: start;
    margin: 0;
    z-index: 10;
}

/*third container*/
.aboutFestival__thirdPic-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    height: 1400px;
    position: relative;
    object-fit: contain;
}

@media screen and (max-width: 1600px) {
    .aboutFestival__thirdPic-container {
        height: 1100px;
    }
}

@media screen and (max-width: 1400px) {
    .aboutFestival__thirdPic-container {
        height: 1000px;
    }
}

@media screen and (max-width: 1200px) {
    .aboutFestival__thirdPic-container {
        height: 900px;
    }
}

@media screen and (max-width: 1000px) {
    .aboutFestival__thirdPic-container {
        height: 800px;
    }
}

@media screen and (max-width: 850px) {
    .aboutFestival__thirdPic-container {
        height: 700px;
    }
}

@media screen and (max-width: 700px) {
    .aboutFestival__thirdPic-container {
        display: flex;
        flex-direction: column-reverse;
        height: fit-content;
    }
}

.aboutFestival__thirdPic-text-and-pic-wrapper {
    display: flex;
    flex-direction: column;
    width: 55%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
}

@media screen and (max-width: 700px) {
    .aboutFestival__thirdPic-text-and-pic-wrapper {
        width: 100%;
        position: relative;
    }
}
.aboutFestival__thirdPic-pic {
    width: 100%;
    margin-bottom: 50px;
}

@media screen and (max-width: 700px) {
    .aboutFestival__thirdPic-pic {
        margin-bottom: 0;
        display: none;
    }
}

.aboutFestival__thirdPic-text {
    max-width: 550px;
    margin-left: 100px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: start;
    z-index: 1;
}

@media screen and (max-width: 1500px) {
    .aboutFestival__thirdPic-text {

        margin-left: 80px;

    }
}

@media screen and (max-width: 700px) {
    .aboutFestival__thirdPic-text {
        width: 100%;
        margin-left: 0;
        max-width: 100%;
        margin-top: 20px;
    }
}

.aboutFestival__thirdPic-big-pic {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5px;
    width: 50%;
    object-fit: contain;
}

@media screen and (max-width: 700px) {
    .aboutFestival__thirdPic-big-pic {
        width: 100%;
        position: relative;
    }
}

.aboutFestival__green-circle {
    background-image: url(../../../../images/aboutFesImages/greencircle.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    bottom: -255px;
    right: -50px;
    width: 455px;
    height: 455px;
    z-index: 0;
}

@media screen and (max-width: 700px) {
    .aboutFestival__green-circle {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        bottom: -180px;
        right: -50px;
    }
}

.aboutFestival__yellow-circle {
    background-image: url(../../../../images/aboutFesImages/yellowcircle.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: -25px;
    left: -80px;
    width: 550px;
    height: 550px;
    z-index: 0;
}

@media screen and (max-width: 700px) {
    .aboutFestival__yellow-circle {
        width: 300px;
        height: 300px;
        border-radius: 50%;
        top: -150px;
        left: -80px;
    }
}

/*4-5*/

.aboutFestival__four-wrapper {
    width: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    margin-top: 100px;
    margin-bottom: 50px;
}

@media screen and (max-width: 700px) {
    .aboutFestival__four-wrapper  {
        flex-direction: column-reverse;
        margin-bottom: 0px;
        margin-top: 40px;
    }
}

.aboutFestival__four-img {
    width: 55%;
    object-fit: contain;
}

@media screen and (max-width: 700px) {
    .aboutFestival__four-img {
        width: 100%;
    }
}

.aboutFestival__four-text {
    width: 50%;
    margin-right: 20px;
    text-align: start;
}

@media screen and (max-width: 700px) {
    .aboutFestival__four-text {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 40px;
    }
}

.aboutFestival__five-wrapper {
    width: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    margin-bottom: 50px;
    justify-content: end;
    align-items: flex-end;
}

@media screen and (max-width: 700px) {
    .aboutFestival__five-wrapper {
        flex-direction: column;
    }
}

.aboutFestival__five-img {
    width: 55%;
    object-fit: contain;
}

@media screen and (max-width: 700px) {
    .aboutFestival__five-img {
        width: 100%;
        margin-bottom: 20px;
    }
}
.aboutFestival__five-text {
    width: 50%;
    margin: 0;
    margin-left: 20px;
    text-align: start;
}

@media screen and (max-width: 700px) {
    .aboutFestival__five-text {
        width: 100%;
        margin-right: 0px;
    }
}

.aboutFestival__title {
    font-weight: bold;
    font-size: 24px;
    text-align: start;
    margin-top: 0;
    margin-bottom: 20px;
}

@media screen and (max-width: 700px) {
    .aboutFestival__title {
        font-size: 18px;
    }
}