.footer {
    background-color: #D9D9D9;
    min-height: 200px;
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;

    position: relative;
}

@media screen and (max-width: 1065px) {
    .footer {
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media screen and (max-width: 770px) {
    .footer {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}


.footer__links-container {
    padding: 0;
    display: flex;
    text-align: start;

    
}

.footer__link {
    list-style-type: none;
    margin-left: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    
}

.footer__link:hover {
    opacity: 0.7;
}

.footer__contact-item-link {
    font-size: 36px;
    color: #2f80ed;
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .footer__contact-item-link {
        font-size: 28px;
    }
}

.footer__contacts-container {
    display: flex;
}


.footer__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-star {
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 4;
    width: 40px;
    height: 40px;

    background-image: url(../../images/haifa/star.svg);

}

.footer__second-orange-second-star {
    position: absolute;
    bottom: 40px;
    right: 50px;
    z-index: 4;
    width: 20px;
    height: 20px;

    background-image: url(../../images/haifa/star.svg);

}
.footer__third-orange-star {

}
.footer__fourth-orange-star {

}
.footer__fifth-orange-star {

}