.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-btn {
    background-color: #000;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    margin: 0 10px;
}

.pagination-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination-pages {
    display: flex;
}

.pagination-page {
    background-color: transparent;
    border: 1px solid #FBBB00;
    color: #FBBB00;
    border-radius: 50%;
    margin: 0 5px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.pagination-page.active {
    background-color: #FBBB00;
    color: #fff;
    text-decoration: none;
}

.pagination-page:hover {
    border: 3px solid #FBBB00;
}

.lots__link {
    text-decoration: none;
    color: grey;
    font-size: 14px;
    text-align: start;
    cursor: pointer;
}

.lots__name {
    text-align: start;
    margin: 0;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 170px;
}

.lots__section {
    /*width: calc(100% - 70px);
    padding-left: 35px;
    padding-right: 35px;*/
    width: 100%;
    padding-bottom: 100px;
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;  
    justify-content: center;
}

.lots__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    max-width: 300px;
    
    padding: 10px;
    border-radius: 8px;
}

.lots__title {
    font-size: 14px;
    color: gray;
    margin: 0;
}

.lots__wrapper {
    display: grid;
    max-width: 1000px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    padding: 0;
    list-style-type: none;
    margin: auto;
    margin-top: 0;
    gap: 20px
}

.lots__imgContainer{
    width: 170px;
    height: 170px;
    margin: auto;
    margin-bottom: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    cursor: pointer;
}

.lots__img {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    object-fit: cover;
    border-radius: 8px;
}

.lots__textContainer {
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    box-sizing: border-box;
}

.lots__backbtn {
    color: black;
    opacity: 0.7;
    font-size: 14px;
    display: flex;
    text-align: start;
    align-self: flex-start;
    cursor: pointer;
}

.lots__backbtn:hover {
    opacity: 1;
}

@media screen and (max-width: 1300px) {
    .lots__wrapper {
        max-width: 800px;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }
}

@media screen and (max-width: 1000px) {
    .lots__section {
        padding-bottom: 50px;
        /*padding-left: 20px;
        padding-right: 20px;
        width: calc(100% - 40px);*/
        width: 100%;
    }
}

@media screen and (max-width: 840px) {
    .lots__wrapper {
        grid-template-columns: unset;
        grid-template-columns: 1fr 1fr;
    }
    .lots__imgContainer{
        width: 150px;
        height: 150px;
    }
    
    .lots__textContainer {
        width: 150px;
    }

    .lots__container {
        padding: 10px;
    }

    .lots__name {
        width: 150px;
    }
}

@media screen and (max-width: 760px) {
    .lots__imgContainer{
        align-self: center;
        border: 1px solid #D9D9D9;
    }

    .lots__img{
        border: 2px solid #fff;
    }
}
@media screen and (max-width: 480px) {
    .pagination-page {
        width: 30px;
        height: 30px;
        font-size: 18px;
    }
}

@media screen and (max-width: 432px) {
    .lots__link {
        font-size: 12px;
    }

    .lots__name {
        text-align: start;
        margin: 0;
        margin-bottom: 5px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 130px;
        font-size: 14px;
    }
    .lots__imgContainer{
        width: 100%;
        height: 130px;
    }
    
    .lots__textContainer {
        width: 130px;
    }

    .lots__container {
        padding: 5px;
    }

    .lots__wrapper {
        min-width: 100%;
    }    
}

@media screen and (max-width: 375px) {
    .lots__imgContainer{
        height: 110px;
    }
    
    .lots__textContainer {
        width: 110px;
    }

}

/*/////////*/
