.review__container {
    display: flex;
    max-width: 1000px;
    border: 1px solid #D9D9D9;
    padding: 20px;
    border-radius: 8px;
}
.review__imgContainer{
    width: 174px;
    height: 174px;
    margin-right: 43px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
}
.review__img {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    object-fit: cover;
    border-radius: 50%;
}
.review__textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
}
.review__name {
    text-align: start;
    margin: 0;
    margin-bottom: 5px;
    font-weight: bold;
}
.review__job {
    text-align: start;
    margin: 0;
    margin-bottom: 20px;
}
.review__text {
    max-width: 800px;
    text-align: start;
    margin: 0;
}

@media screen and (max-width: 1200px) {
    .review__text {
        max-width: 600px;
        text-align: start;
        margin: 0;
    }
}

@media screen and (max-width: 950px) {
    .review__text {
        max-width: 450px;
    }

    .review__imgContainer{
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 760px) {
    .review__text {
        max-width: 450px;
    }

    .review__imgContainer{
        width: 200px;
        height: 200px;
        align-self: flex-end;
        margin-bottom: 10px;
        border: 1px solid #D9D9D9;
    }

    .review__img{
        border: 2px solid #fff;
    }

    .review__container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .review__textContainer {
        align-self: center;
    }
}

@media screen and (max-width: 480px) {

    .review__imgContainer{
        align-self: center;
        margin-right: 0;
    }

    .review__textContainer {
        align-self: center;
        justify-content: center;
        align-items: center;
    }

    .review__job {
        text-align: center;
    }
}