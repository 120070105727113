
.camp-haifa__section {
    display: flex;
    flex-direction: column;
    width: calc(100% - 160px);

    padding-left: 80px;
    padding-right: 80px;
   
    padding-top: 80px;
    padding-bottom: 80px;

    background-color: #fff;
    position: relative;
}

@media screen and (max-width: 1065px) {
    .camp-haifa__section {
        padding-left: 12px;
        padding-right: 12px;
        width: calc(100% - 24px);
    }
}

@media screen and (max-width: 770px) {
    .camp-haifa__section {
        padding-top: 40px;
    }
}

.camp-haifa__title {
    font-weight: bold;
    font-size: 32px;
    text-align: start;
    margin-top: 0;
    
    margin-bottom: 40px;
}

@media screen and (max-width: 1065px) {
    .camp-haifa__title {
        font-size: 32px;
        
    }
}

@media screen and (max-width: 770px) {
    .camp-haifa__title {
        margin-bottom: 40px;
    }
}

.camp-haifa__wrapper {
    padding: 0;
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
    align-items: center;
    
}
.camp-haifa__container {
    list-style-type: none;
    width: 500px;
    height: 300px;
    margin: 10px;
    padding: 20px;
    border-radius: 15px;
    border: 5px dotted #2ab09e;

    justify-content: center;
    align-items: center;

    position: relative;
}

@media screen and (max-width: 1300px) {
    .camp-haifa__container {
        width: 350px;
        height: 350px;
    }
}

@media screen and (max-width: 865px) {
    .camp-haifa__container {
        width: 550px;
        height: fit-content;
    }
}

@media screen and (max-width: 650px) {
    .camp-haifa__container {
        width: 500px;
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 600px) {
    .camp-haifa__container {
        width: 450px;
    }
}

@media screen and (max-width: 550px) {
    .camp-haifa__container {
        width: 400px;

    }
}

@media screen and (max-width: 480px) {
    .camp-haifa__container {
        width: 310px;
    }
}

@media screen and (max-width: 400px) {
    .camp-haifa__container {
        width: 100%;
        padding: 10px;
    }
}



.camp-haifa__second-wrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.camp-haifa__second-container {
    list-style-type: none;
    text-align: start;
    margin-bottom: 10px;

    font-size: 18px;
}

@media screen and (max-width: 444px) {
    .camp-haifa__second-container {
        margin-bottom: 5px;
        font-size: 14px;
    }
}

.camp-haifa__time {
    color: #2ab09e;
    margin-right: 10px;
    font-weight: 500;
    z-index: 2;
}

.camp-haifa__second-orange-star {
    background-image: url(../../images/haifa/star.svg);
    position: absolute;
    top: 20px;
    right: 30px;
    width: 50px;
    height: 50px;
    z-index: 0; 
}

@media screen and (max-width: 772px) {
    .camp-haifa__second-orange-star {
        top: 150px;
    }
}

@media screen and (max-width: 500px) {
    .camp-haifa__second-orange-star {
        top: 20px;
    }
}


.camp-haifa__orange-star {
    background-image: url(../../images/haifa/star.svg);
    position: absolute;
    top: 180px;
    left: 40px;
    width: 50px;
    height: 50px;
    z-index: 0;
    
}

@media screen and (max-width: 729px) {
    .camp-haifa__orange-star {
        top: 150px;
    }
}

.camp-haifa__third-orange-star {
    background-image: url(../../images/haifa/star.svg);
    position: absolute;
    bottom: 70px;
    left: 60px;
    width: 50px;
    height: 50px;
    z-index: 0;
}
.camp-haifa__fourth-orange-star {
    background-image: url(../../images/haifa/star.svg);
    position: absolute;
    bottom: 40px;
    left: 130px;
    width: 50px;
    height: 50px;
    z-index: 0;
}

@media screen and (max-width: 444px) {
    .camp-haifa__third-orange-star {
        bottom: 20px;
        width: 30px;
        height: 30px;
    }
}

@media screen and (max-width: 444px) {
    .camp-haifa__fourth-orange-star {
        bottom: 10px;
        width: 20px;
        height: 20px;
    }
}

.camp-haifa-orange-small-star {
    position: absolute;
    bottom: 40px;
    right: 50px;
    z-index: 0;
    width: 20px;
    height: 20px;

    background-image: url(../../images/haifa/star.svg);

}

.camp-haifa__main-text {
    margin-bottom: 40px;
}

.camp-haifa__title-and-btn-container {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 500px) {
    .camp-haifa__title-and-btn-container {
        flex-direction: column;
    }
}

.camp-haifa__link-btn-down {
    height: 50px;
    width: 350px;
    
    background-color: #FDD256;
    border-radius: 20px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2ab09e;
    cursor: pointer;
    align-self: center;
    margin: auto;
    margin-top: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 500px) {
    .camp-haifa__link-btn-down {
        width: 85%;
    }
}

.camp-haifa__link-btn-down:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.camp-haifa__link-btn {
    height: 40px;
    width: 150px;
    border: 2px solid #FDD256;
    border-radius: 20px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2ab09e;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.camp-haifa__link-btn:hover {
    background-color: #FDD256;
    color: #2ab09e;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 500px) {
    .camp-haifa__link-btn {
        background-color: #FDD256;
        color: #2ab09e;
        width: 70%;
        align-self: center;
        margin-bottom: 40px;
        font-weight: 600;
    }
}

.camp-haifa__wrapper-hosts {
    padding: 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.camp-haifa__container-hosts {
    list-style-type: none;
    width: 350px;
    border-radius: 20px;
    border: 1px solid #dfe4e3;

    background-color: transparent;
    padding: 10px;
    margin: 10px;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 800px) {
    .camp-haifa__container-hosts {
        width: 90%;
    }
}


.camp-haifa__host-pic {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
    object-position: top;
}

.camp-haifa__host-name {
    font-weight: 600;
}

.camp-haifa__subtitle-hosts {
font-size: 32px;
font-family: "Roboto", sans-serif;
}

.camp-haifa__subtitle {
    font-weight: 500;
}