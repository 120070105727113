.theaterHaifa__main-container {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
    position: relative;
    background-color:#F5F5F5;
    
}

@media screen and (max-width: 1065px) {
    .theaterHaifa__main-container {
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media screen and (max-width: 770px) {
    .theaterHaifa__main-container {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.theaterHaifa__main-title {
    font-weight: bold;
    font-size: 32px;
    text-align: start;
    margin-top: 0;
    
    margin-bottom: 80px;
}

@media screen and (max-width: 1065px) {
    .theaterHaifa__main-title {
        font-size: 32px;
        
    }
}

@media screen and (max-width: 770px) {
    .theaterHaifa__main-title {
        margin-bottom: 40px;
    }
}

/*onePerformance__container*/
.onePerformance__container {
    width: 300px;
    height: 300px;
    background-color: #2ab09e;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0.8;
    color: #fff;
    position: relative;
    padding: 10px;
    list-style-type: none;
    margin: 5px;
    margin-top: 20px;
    border-radius: 15px;
    cursor: pointer;
}

@media screen and (max-width: 357px) {
    .onePerformance__container {
        width: 100%;
    }
}

.onePerformance__container:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.onePerformance__container_important {
    width: 290px;
    height: 290px;
    background-color: #FDD256;
    border: 5px dotted #2ab09e;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0.8;
    color: #424646;
    position: relative;
    padding: 10px;
    list-style-type: none;
    margin: 5px;
    margin-top: 20px;
    border-radius: 15px;
    cursor: pointer;
    text-decoration: none;
}

@media screen and (max-width: 357px) {
    .onePerformance__container_important {
        width: calc(100% - 10px);
    }
}

.onePerformance__container_important:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.onePerformance__text-container {
    height: 250px;
    overflow-y: scroll;
    margin-top: 40px;
}

.onePerformance__link-btn {
    width: 150px;
    height: 40px;
    border-radius: 20px;
    background-color: #FDD256;
    opacity: 1;
    color: #2f80ed;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: -20px;
    right: 0;
    text-decoration: none;
}

.onePerformance__link-btn:hover {
    background-color: #2f80ed;
    color: #FDD256;
} 

.theaterHaifa__wrapper {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
}

.onePerformance__date {
    color: #fff;
    position: absolute;
    top: 10px;
    left: 10px;
}

.onePerformance__title {
    font-weight: 600;
    font-size: 16px;  
}

.onePerformance__description {
    text-align: start;
}

.onePerformance__age {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #F5F5F5;
    background-color: #d44a4a;

    position: absolute;
    right: -10px;
    top: 5px;
    z-index: 5;
}

.onePerformance__container {
    text-decoration: none;
}

.onePerformance__link-btn_important {
    width: 150px;
    height: 40px;
    border-radius: 20px;
    background-color: #2ab09e;
    
    opacity: 1;
    color: #FDD256;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: -20px;
    right: 0;
    text-decoration: none;
}

.onePerformance__link-btn_important:hover {
    background-color: #2f80ed;
    color: #FDD256;
} 

.onePerformance__date_important {
    color: #424646;
    position: absolute;
    top: 10px;
    left: 10px;
}