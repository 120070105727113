.individualPage-section {
    min-height: 50vh;
}

.individualPage-text-container {
    max-width: 1000px;
    text-align: start;
    display: flex;
    flex-direction: column;
    margin: auto;

}

@media screen and (max-width: 1200px) {
    .individualPage-text-container {
        max-width: 1000%;
    }
}

.individualPage-age {
    border-radius: 50%;
    background-color: brown;
    color: #fff;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.individualPage-link-ticket {
    border-radius: 30px;
    background-color: rgb(243, 130, 37);
    color: #fff;
    width: 250px;
    height: 50px;
    position: absolute;
    top: 30px;
    right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.individualPage-section {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 80px;
    padding-right: 80px;
}

@media screen and (max-width: 1200px) {
    .individualPage-section {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media screen and (max-width: 500px) {
    .individualPage-section {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.individualPage-discription {

}