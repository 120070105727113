.importantNotes__section {
    background-color: #fff;
    padding: 43px;
}

.importantNotes__squares-container {
    display: flex;
    justify-content: space-between;
}

.importantNotes__triangles {
    background-image: url(../../../../images/purim/purim2025/triangles.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 618px;
    height: 77px;
    display: flex;
    margin: auto;
}

.importantNotes__squares {
    background-image: url(../../../../images/purim/purim2025/squares.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 275px;
    height: 142px;
}

.importantNotes__subtitle { 
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    margin-top: 60px;
}

.importantNotes__wrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}

.importantNotes__container {
    list-style-type: none;
    padding-bottom: 12px;
    border-bottom: 0.5px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
    text-align: start;
    width: 100%;
    margin-top: 10px;
    min-height: 57px;
}

.importantNotes__container:last-of-type {
    border-bottom: none;
}

.importantNotes__triangles-container {
    display: flex;
}

.importantNotes__triangle-color {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 82px;
    height: 75px;
}

.importantNotes__triangle-yellow-violet {
    background-image: url(../../../../images/purim/purim2025/yellowviolet.png);
}

.importantNotes__triangle-pink-violet {
    background-image: url(../../../../images/purim/purim2025/pinkviolet.png);
}

.importantNotes__triangle-blue-violet {
    background-image: url(../../../../images/purim/purim2025/blueviolet.png);
}

.importantNotes__triangle-yellow-blue {
    background-image: url(../../../../images/purim/purim2025/yellowblue.png);
}
 

.importantNotes__title {
    font-size: 24px;
    margin: 0;
    max-width: 761px;
}

.importantNotes__ticketPurchasingSystemPrice {
    font-size: 24px;
    margin: 0;
    font-weight: bold;
}
.importantNotes__time-container {
    max-width: 190px;
    font-size: 24px;
    text-align: end;
}

.importantNotes__buy-tikets-btn {
    text-decoration: none;
    width: 185px;
    height: 50px;
    color: #fff;
    background-color: #1563FF;
    border-radius: 12px;
    text-transform: uppercase;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.importantNotes__title-notes {
    max-width: 756px;
    font-size: 16px;
}

.importantNotes__text {
    font-size: 24px;
    margin: 0;
    text-align: start;
    margin-bottom: 30px; 
}

.importantNotes__time {
    font-weight: bold;
}

.importantNotes__note {
    font-weight: bold;
}

.importantNotes__threelinesofsquares {
    background-image: url(../../../../images/purim/purim2025/threelinesofsquares.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 478px;
    height: 131px;
    display: flex;
    margin-top: 100px;
}

.importantNotes__important-container {
    display: flex;
}

.importantNotes__pics-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 83px;
    align-items: flex-end;
}

.importantNotes__pics-first {
    background-image: url(../../../../images/purim/purim2025/pictureofthreesquares.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100px;
    height: 197px;
}

.importantNotes__pics-second {
    background-image: url(../../../../images/purim/purim2025/pictureofsevensquares.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 87px;
    height: 308px;
}

.importantNotes__pics-third {
    background-image: url(../../../../images/purim/purim2025/alotofsquares.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 131px;
    height: 302px;
}

.importantNotes__pics-last {
    background-image: url(../../../../images/purim/purim2025/lastsquaresinnotes.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 131px;
    height: 347px;
}

@media screen and (max-width: 1320px) {
    .importantNotes__title {
        max-width: 600px;
    }
}

@media screen and (max-width: 1150px) {
    .importantNotes__title {
        max-width: 500px;
    }

    .importantNotes__pics-container {
        margin-left: 20px;
    }

    .importantNotes__squares-container {
        flex-direction: column-reverse;
    }

    .importantNotes__squares {
        align-self: flex-end;
    }
}

@media screen and (max-width: 900px) {
    .importantNotes__wrapper {
        margin-bottom: 50px;
    }

    .importantNotes__title {
        max-width: 340px;
        font-size: 18px;
    }

    .importantNotes__subtitle { 
        font-size: 18px;
        margin-top: 43px;
    }

    .importantNotes__text {
        font-size: 18px;
    }

    .importantNotes__ticketPurchasingSystemPrice {
        font-size: 18px;
    }

    .importantNotes__time-container {
        font-size: 18px;
    }

    .importantNotes__buy-tikets-btn {
        font-size: 18px;
        height: 40px;
    }

    .importantNotes__triangles {
        width: 443px;
        height: 56px;
    }

    .importantNotes__triangle-color {
        width: 60px;
        height: 55px;
    }

    .importantNotes__squares {
        width: 209px;
        height: 101px;
    }

    .importantNotes__pics-first {
        width: 65px;
        height: 130px;
    }
    
    .importantNotes__pics-second {
        width: 65px;
        height: 232px;
    }
    
    .importantNotes__pics-third {
        width: 101px;
        height: 232px;
    }
    
    .importantNotes__pics-last {
        width: 101px;
        height: 267px;
    }

    .importantNotes__threelinesofsquares {
        width: 367px;
        height: 101px;
        margin-top: 56px;
    }
}

@media screen and (max-width: 700px) {
    .importantNotes__secondcontainer {
        flex-direction: column;
    }

    .importantNotes__time-container {
        max-width: 100%;
    }

    .importantNotes__wrapper {
        margin-bottom: 43px;
    }

    .importantNotes__squares {
        width: 104px;
        height: 50px;
    }

    .importantNotes__title {
        max-width: 201px;
        font-size: 16px;
    }

    .importantNotes__title-notes-tel {
        max-width: 100%;
        display: flex;
        align-items: flex-start;
        text-align: start;
    }

    .importantNotes__subtitle { 
        font-size: 16px;
    }

    .importantNotes__text {
        font-size: 16px;
    }

    .importantNotes__ticketPurchasingSystemPrice {
        font-size: 16px;
    }

    .importantNotes__time-container {
        font-size: 16px;
        align-items: flex-start;
    }

    .importantNotes__buy-tikets-btn {
        font-size: 16px;
        width: 104px;
    }

    .importantNotes__triangles {
        width: 221px;
        height: 28px;
    }

    .importantNotes__pics-first {
        display: none;
    }
    
    .importantNotes__pics-second {
        display: none;
    }
    
    .importantNotes__pics-third {
        display: none;
    }
    
    .importantNotes__pics-last {
        display: none;
    }

    .importantNotes__threelinesofsquares {
        width: 182px;
        height: 50px;
        margin-top: 30px;
    }
}