.schedule_section {
    background-color:#fff;
    padding-bottom: 150px;
    width: 100%;
}

.schedule_section__date-container {
    max-width: 730px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
}

.schedule_section__date {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-size: 120px;
    margin: 0;
    border-bottom: 1px solid #D9D9D9;
    padding: 20px;
    cursor: pointer;
    position: relative;
    z-index: 2;
}

.schedule_section__date:last-of-type {
    border-bottom: none;
}


.schedule_section__date:hover {
    color: #2f80ed;
}

.schedule_section_btn {
    height: 60px;
    width: 300px;
    border: 2px solid #FDD256;
    border-radius: 30px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2ab09e;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 26px;
    margin-top: 85px;
    margin-right: 43px;
}

.schedule_section_btn:hover {
    background-color: #FDD256;
    color: #2ab09e;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.schedule_section_header_and_btn_container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 80px;
    position: relative;
}

.schedule_section_left_side_stars {
    background-image: url(../../../../images/hanukkah/stars.png);
    background-position: center;
     background-repeat: no-repeat;
    background-size: contain;
    width: 110px;
    height: 508px;
    position: absolute;
    left: -210px;
    top: -10px;
}

.schedule_section_right_side_stars {
    background-image: url(../../../../images/hanukkah/stars.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 110px;
    height: 508px;

    position: absolute;
    right: -210px;
    top: -80px;
}

.schedule_section_zig_zag {
    background-image: url(../../../../images/hanukkah/zig-zags.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 288px;
    height: 163px;

    position: absolute;
    right: 400px;
    top: 0;
}

@media screen and (max-width: 1288px) {
    .schedule_section_left_side_stars {
        width: 90px;
        height: 400px;
        left: -110px;
    }

    .schedule_section_right_side_stars {
        width: 90px;
        height: 400px;
        right: -110px;
    }

    .schedule_section_zig_zag {
        width: 200px;
        height: 150px;
    }
}

@media screen and (max-width: 1065px) {
    .schedule_section__date {
        font-size: 100px;
        
    }
}

@media screen and (max-width: 1000px) {
    .schedule_section_left_side_stars {
        width: 80px;
        height: 300px;
        left: 0;
    }

    .schedule_section_right_side_stars {
        width: 80px;
        height: 300px;
        right: 0;
    }

    .schedule_section_zig_zag {
        width: 128px;
        height: 72px;

        right: 50px;
    }

    .schedule_section_btn {
        height: 50px;
        width: 250px;
        font-size: 20px;
    }
}

@media screen and (max-width: 840px) {
    .schedule_section_btn { 
        margin-right: 20px;
    }
}

@media screen and (max-width: 770px) {
    .schedule_section__date-container {
        max-width: 400px;
    }

    .schedule_section__date {
        font-size: 50px;
    }

    .schedule_section_left_side_stars {
        width: 80px;
        height: 300px;
        left: -100px;
    }

    .schedule_section_right_side_stars {
        width: 80px;
        height: 300px;
        right: -100px;
    }

    .schedule_section_btn {
        height: 40px;
        width: 200px;
        font-size: 18px;
    }
}

@media screen and (max-width: 670px) {
    .schedule_section_left_side_stars {
        width: 80px;
        height: 300px;
        left: -50px;
    }

    .schedule_section_right_side_stars {
        width: 80px;
        height: 300px;
        right: -50px;
    }
}

@media screen and (max-width: 600px) {
    .schedule_section_header_and_btn_container {
        flex-direction: column;
        margin-left: 0;
        align-self: center;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 0;
    }

    .schedule_section_btn { 
        margin: auto;
        margin-bottom: 40px;
    }
}


@media screen and (max-width: 510px) {
    .schedule_section_left_side_stars {
        width: 300px;
        height: 300px;
        bottom: -200px;
        top: unset;
        left: 40px;
        transform: rotate(90deg);
    }

    .schedule_section_right_side_stars {
        display: none;
    }
}

@media screen and (max-width: 380px) {
    .schedule_section_zig_zag {
        width: 128px;
        height: 72px;
        right: 10px;
        top: -70px
    }

    .schedule_section_left_side_stars {
        left: 20px;
    }
}

@media screen and (max-width: 323px) {
    .schedule_section__date {
        font-size: 40px;
    }

    .schedule_section_left_side_stars {
        left: 20px;
        bottom: -120px;
        width: 280px;
        height: 200px;
    }

    .schedule_section {
        padding-bottom: 100px;
    }
}