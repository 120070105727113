.presentation__section {
    background-color: #fff;
    width: calc(100% - 86px);
    padding: 43px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.presentation__title-container {
    display: flex;
}

.presentation__first-pic {
    background-image: url(../../../../images/purim/purim2025/presentationfirstpic.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 479px;
    height: 84px;
    align-self: flex-start;
}

.presentation__left-pic {
    background-image: url(../../../../images/purim/purim2025/presentationleftpic.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 174px;
    height: 222px;
    margin-top: 50px;
}
.presentation__right-pic {
    background-image: url(../../../../images/purim/purim2025/presentationrightpic.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 135px;
    height: 173px;
    margin-top: 50px;
}
.presentation__text-container {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.presentation__fourhorizontalsquares {
    background-image: url(../../../../images/purim/purim2025/fourhorizontalsquares.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 169px;
    height: 40px;
    margin: auto;
}

.presentation__text {
    font-size: 24px;
    margin: 10px;
}
.presentation__title {
    font-size: 48px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.presentation__bold {
    font-weight: bold;
}

.presentation__wrapper {
    padding: 0;
}
.presentation__li {
    list-style-type: none;
    display: flex;
}
.presentation__li-arrow {
    width: 82px;
    height: 75px;
    margin-right: 21px; 
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: rotate(-90deg);
}

.importantNotes__triangle-yellow-violet {
    background-image: url(../../../../images/purim/purim2025/yellowviolet.png);
}

.importantNotes__triangle-pink-violet {
    background-image: url(../../../../images/purim/purim2025/pinkviolet.png);
}

.importantNotes__triangle-blue-violet {
    background-image: url(../../../../images/purim/purim2025/blueviolet.png);
}
.importantNotes__triangle-yellow-blue1 {
    background-image: url(../../../../images/purim/purim2025/yellowblue1.png);
    transform: unset;
}

.importantNotes__triangle-lightblue-blue {
    background-image: url(../../../../images/purim/purim2025/lightblueblue.png);
    transform: unset;
}

.importantNotes__triangle-blue-pink {
    background-image: url(../../../../images/purim/purim2025/bluepink.png);
    transform: unset;
}

.importantNotes__triangle-pink-yellow {
    background-image: url(../../../../images/purim/purim2025/pinkyellow.png);
    transform: unset;
}

.importantNotes__triangle-yellow-lightblue {
    background-image: url(../../../../images/purim/purim2025/yellowlightblue.png);
    transform: unset;
}

.importantNotes__triangle-lightblue-violet {
    background-image: url(../../../../images/purim/purim2025/lightblueviolet.png);
    transform: unset;
}

.importantNotes__triangle-violet-pink {
    background-image: url(../../../../images/purim/purim2025/violetpink.png);
    transform: unset;
}

.presentation__li-text-container {
    max-width: 803px;
    text-align: start;
    line-height: 50px;
}
.presentation__li-title {
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;

    margin-top: 15px;
    
}
.presentation__li-text{
    font-weight: 300;
    font-size: 24px;
}
.presentation__li-link {
    color: #1563ff;
    font-weight: 300;
    font-size: 24px;
    text-decoration: none;
}
.presentation__li-notes {
    font-weight: 300;
    font-size: 24px;
}
.presentation__li-btn {
    color: #fff;
    background-color: #7D26bf;
    font-weight: 300;
    font-size: 24px;
    border-radius: 12px;
    height: 42px;

    padding-left: 16px;
    padding-right: 16px;

    padding-top: 5px;
    padding-bottom: 5px;

    border: none;
    cursor: pointer;
    text-decoration: none;
}
.presentation__li-btn:hover {

}
.presentation__li-triangles {
    width: 543px;
    height: 103px;
    margin: auto;
    margin-bottom: 65px;
    margin-top: 65px;
    background-image: url(../../../../images/purim/purim2025/presentationtriangles.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.presentation__li-triangles-last-of-type {
    display: none;
} 

.presentation__presentationsquares {
    width: 478px;
    height: 131px;

    background-image: url(../../../../images/purim/purim2025/presentationsquares.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    display: flex;
    align-self: flex-end;
}

@media screen and (max-width: 900px) {
    .presentation__section {
        width: calc(100% - 64px);
        padding: 32px;
    }
    .presentation__li-text-container {
        max-width: 460px;
        line-height: unset;
        font-size: 18px;
    }
    .presentation__li-arrow {
        width: 46px;
        height: 46px;
    }
    .presentation__li-triangles {
        width: 330px;
        height: 63px;
    }
    .presentation__li-title {
        line-height: unset;
        font-size: 18px;
    }
    .presentation__li-text {
        line-height: unset;
        font-size: 18px;
    }
    .presentation__li-notes {
        line-height: unset;
        font-size: 18px;
    }
    .presentation__li-triangles {
        line-height: unset;
        font-size: 18px;
    }
    .presentation__li-btn {
        line-height: unset;
        font-size: 18px;
    }
    .presentation__li-link {
        line-height: unset;
        font-size: 18px;
    }
    .presentation__presentationsquares {
        width: 419px;
        height: 115px;
    }
    .presentation__fourhorizontalsquares {
        width: 137px;
        height: 31px;
    }

    .presentation__first-pic {
        width: 393px;
        height: 69px;
        margin-bottom: 45px;
    }

    .presentation__left-pic {
        width: 123px;
        height: 179px;
    }
    .presentation__right-pic {
        width: 89px;
        height: 138px;
    }
}

@media screen and (max-width: 700px) {

    .presentation__li-text-container {
        max-width: 311px;
        line-height: 28px;
        font-size: 16px;
        display: flex;
        flex-direction: column-reverse;
    }
    .presentation__li-triangles {
        width: 222px;
        height: 42px;
        margin: auto;
        margin-top: 35px;
        margin-bottom: 35px;


        line-height: 28px;
        font-size: 16px;
    }

    .presentation__li-triangles-last-of-type {
        display: unset;
    }

    .presentation__li-title {
        line-height: 28px;
        font-size: 16px;
    }
    .presentation__li-text {
        line-height: 28px;
        font-size: 16px;
    }
    .presentation__li-notes {
        line-height: 28px;
        font-size: 16px;
    }
    .presentation__li-btn {
        line-height: 28px;
        font-size: 16px;
    }
    .presentation__li-link {
        line-height: 28px;
        font-size: 16px;
    }
    .presentation__presentationsquares {
        width: 183px;
        height: 50px;
    }
    .presentation__li-arrow {
        display: none;
    }
    .presentation__fourhorizontalsquares {
        width: 69px;
        height: 16px;
    }
    .presentation__text {
        font-size: 16px;
    }

    .presentation__title {
        font-size: 30px;
    }
    .presentation__first-pic {
        width: 199px;
        height: 35px;
        margin-bottom: 45px;
    }

    .presentation__left-pic {
        display: none;
    }
    .presentation__right-pic {
        display: none;
    }
}