.header__announcement-pic {
    width: 580px;
    height: 580px;
    padding-bottom: 0;
    margin-top: 25px;
    margin-left: 25px;
    background-size: cover;
    align-self: flex-end;
} 

@media screen and (max-width: 1050px) {
    .header__announcement-pic {
        width: 500px;
        height: 500px;
    }
}

@media screen and (max-width: 880px) {
    .header__announcement-pic {
        max-width: 100%;
        height: fit-content;
        align-self: center;
        margin-left: 0px;
        margin-right: 0px;
        
    }
}
  
.header__btn {
    border-radius: 30px;
    background-color: transparent;
    border: 3px solid #2f80ed;
    /*box-shadow: 0 3px 6px #999;*/
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    color: black;
    text-decoration: none;
    width: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 44px;
}
  
.header__btn:first-of-type {
    margin-left: 0;
} 
  
.header__btn:hover {
    background-color: #2f80ed;
}

@media screen and (max-width: 460px) {
    .header__btn {
        margin: 5px;
        width: 150px;
        font-size: 12px;
    }
}
  
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
      to {
        transform: rotate(360deg);
    }
}
    
.rotation {
    animation: rotation 40s ease infinite;
}
    
  
.header__container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;

}

@media screen and (max-width: 1050px) {
    .header__container {
        margin-left: 25px;
        margin-right: 25px;
        align-items: center;
        justify-content: center;
    }
}  

@media screen and (max-width: 880px) {
    .header__container {
        margin-left: 25px;
        margin-right: 25px;
        flex-direction: column-reverse;
        align-self: center;
    }
}   

.header__btn-container {
    display: flex;
    flex-direction: column;
}
  
.header__title {
    max-width: 500px;
    font-size: 56px;
    text-align: center;
}

@media screen and (max-width: 980px) {
    .header__title {
        font-size: 50px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 450px) {
    .header__title {
        font-size: 40px;
        margin-bottom: 20px;
        margin-right: 0px;
        text-align: center;
        max-width: 250px;

    }
}

@media screen and (max-width: 350px) {
    .header__title {
        font-size: 34px;
        
        text-align: center;
        display: flex;
        margin: auto;
        margin-bottom: 20px;
        margin-top: 25px;
    }
}
  
.header__text-wrapper {
    position: relative; 

    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: center;
}
  
.header__square-pic {
    height: 200px;
    width: 200px;
    background-color: #2f80ed;
    position: absolute;
    left: 380px;
    top: 150px;
    opacity: 0.5;
    z-index: 0;
}
  
.kaufman__triangle {
    background-image: url(../../images/triangle.svg);
    background-position: center;
    object-fit: cover;
    width: 70px;
    height: 300px;
    position: absolute;
    left: 100px;
    top: 300;
    z-index: 0;
    opacity: 0.5;
}
  
.triangle {
    background-image: url(../../images/triangle.svg);
    background-position: center;
    object-fit: cover;
    width: 120px;
    height: 40px;
    position: absolute;
    left: 200px;
    top: 100;
    z-index: 0;
    opacity: 0.5;
}
  
.square-pic {
    height: 150px;
    width: 100px;
    background-color: #2f80ed;
    position: absolute;
    left: 300px;
    top: 400px;
    opacity: 0.5;
    z-index: 0;
} 
 
.header__btn-link {
    cursor: pointer;
    text-decoration: none;
    color: #2f80ed;
    margin-bottom: 25px;
    font-size: 20px;
}

@media screen and (max-width: 550px) {
    .header__btn-link {
        display: flex;
        margin: auto;
        align-self: center;
    }
} 
  
.header__btn-link:hover {
    color: #2f80ed;
}

.announce__links-container {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    max-width: 400px;
}

@media screen and (max-width: 550px) {
    .announce__links-container {
        max-width: 320px;
        margin: auto;
        margin-top: 25px;
    }
}

.header__attention-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;

    margin: 10px;
}