.hifa-announce__container {
    background-image: url(../../images/haifa/mainPicHaifa.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
}

@media screen and (max-width: 470px) {
    .hifa-announce__container {
        background-position: left;
    }
}

.hifa__text-wrapper {
    min-height: 670px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding-bottom: 80px;
    padding-left: 80px;
}

@media screen and (max-width: 1065px) {
    .hifa__text-wrapper {
        padding-left: 12px;
    }
}

@media screen and (max-width: 766px) {
    .hifa__text-wrapper {
        padding-bottom: 28px;
    }
}

@media screen and (max-width: 470px) {
    .hifa__text-wrapper {
        min-height: 560px;
        max-height: 560px;
        height: 560px;

        background-color: rgba(255, 255, 255, 0.1);  /*Белый фон с прозрачностью */
        backdrop-filter: blur(2px);
    }
}

.hifa-announce__title {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 40px;
    color: #fff;
    margin: 0;
    text-align: start;
    max-width: 500px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    
}

@media screen and (max-width: 1065px) {
    .hifa-announce__title {
        font-size: 36px;
    }
}

@media screen and (max-width: 770px) {
    .hifa-announce__title {
        font-size: 26px;
        margin-top: 30px;
        font-weight: 700;
        color: black;
        text-shadow: none;
    }
}

@media screen and (max-width: 470px) {
    .hifa-announce__title {
        color: #2e2d2c;
        /*background-color: rgba(255, 255, 255, 0.4);  /*Белый фон с прозрачностью 
        backdrop-filter: blur(10px);
        border-radius: 20px; */
        font-size: 40px;
        font-weight: 600;
        padding: 5px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        text-align: start;
        margin-top: 20px;
        margin-bottom: 0;
        margin-left: 0;
        /*text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        line-height: 1.0;*/
    }
}

@media screen and (max-width: 470px) {
    .hifa-announce__title-date {
        color: #181614;
        font-weight: 500;
    }
}

.newAnnounce__btn-container {
    display: flex;
    margin-top: 29px;
}

@media screen and (max-width: 766px) {
    .newAnnounce__btn-container {
        flex-direction: column;
    }
}

.newAnnounce__btn-link {
    background-color: #FBBB01;
    color: black;
    border-radius: 33.5px;
    text-decoration: none;
    cursor: pointer;
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-size: 20px;
    margin-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 80px 317px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.newAnnounce__btn-link:hover {
    opacity: 0.7;
}

@media screen and (max-width: 1065px) {
    .newAnnounce__btn-link {
        font-size: 14px;
    }
}

@media screen and (max-width: 766px) {
    .newAnnounce__btn-link {
        margin-right: 0px;
        margin-bottom: 10px;
    }
}

.newAnnounce__btn {
    background-color: #FF3F02;
    color: #fff;
    border-radius: 33.5px;
    text-decoration: none;
    cursor: pointer;
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-size: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.newAnnounce__btn:hover {
    opacity: 0.7;
}

@media screen and (max-width: 1065px) {
    .newAnnounce__btn {
        font-size: 14px;
    }
}

.hifa-announce__title-nowrap {
    display: inline-block;
}