.sticky-button {
    position: fixed;
    bottom: 25px;
    right: 25px;
    padding: 10px 20px;
    background-color: #FBBB00;
    color: #fff;
    font-size: 16px;
    font-family: 'Karton', sans-serif;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .sticky-button.visible {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    z-index: 22;
  }
  
.arrowUp {
  background-image: url(../../images/arrowwhite.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;

  transform: rotate(-180deg);
}  
  