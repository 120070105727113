.presenters__section {
    background-color: #fff;
    width: calc(100% - 86px);
    padding-left: 43px;
    padding-right: 43px;
    padding-bottom: 100px;
    padding-top: 300px;
    position: relative;
}

@media screen and (max-width: 1650px) {
    .presenters__section {
        padding-top: 200px;
    }
}

@media screen and (max-width: 1000px) {
    .presenters__section {
        padding-top: 150px;
    }
}

@media screen and (max-width: 635px) {
    .presenters__section {
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 25px;
    }
}

@media screen and (max-width: 500px) {
    .presenters__section {
        padding-top: 100px;
    }
}

.presenters__page-pic {
    background-image: url(../../../images/storytime/bookpagegrey.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-width: 100%;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1650px) {
    .presenters__page-pic  {
        height: 200px;
    }
}

@media screen and (max-width: 1000px) {
    .presenters__page-pic  {
        height: 150px;
    }
}

@media screen and (max-width: 500px) {
    .presenters__page-pic  {
        height: 100px;
    }
}

.presenters__wrapper {
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style-type: 0;
    margin: auto;
    justify-content: center;
    list-style-type: none;
}

.presenters__li_card {
    width: 300px;
    margin: 20px;
    font-family: "Mulish", sans-serif;
}

@media screen and (max-width: 800px) {
    .presenters__li_card {
        width: 250px;
        margin: 10px;
    }
}

.presenters__photo-container {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
    .presenters__photo-container {
        width: 250px;
        height: 250px;
        margin-bottom: 10px;
    }
}

.presenters__photo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.presenters__title {
    color: #374BA5;
    text-align: start;
    font-size: 42px;
    font-family: 'Karton', sans-serif;
}

@media screen and (max-width: 900px) {
    .presenters__title  {
        font-size: 34px;
    }
}

@media screen and (max-width: 500px) {
    .presenters__title  {
        font-size: 24px;
    }
}

.presenters__li_card-title {
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 20px;
}

@media screen and (max-width: 500px) {
    .presenters__li_card-title  {
        font-size: 16px;
        margin-bottom: 10px;
    }
}

.presenters__li_card-discription {
    font-family: "Mulish", sans-serif;
}

@media screen and (max-width: 500px) {
    .presenters__li_card-discription {
        font-size: 14px;
        margin-top: 10px;
    }
}
