
.ourProjectPopup {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: visibility 0.3s; /*opacity 0.3s;*/
    box-sizing: border-box;
    overflow:auto;
    z-index: 10;
   }
   
 .ourProjectPopup__opened {
     visibility: visible;
     display: flex;
     margin: auto;
     justify-content: center;
     align-items: flex-start;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.3);
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     visibility: visible;
     opacity: 1;
     transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
     overflow: auto;
     z-index: 10;
 }
 
 .ourProjectPopup__container {
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     height: fit-content;
     z-index: 10;
     position: fixed;
     top: 0;
     bottom: 0;
     right: 0;
     opacity: 1;
     box-sizing: border-box;
     width: 200px;
     width: 50%;
     background-color: #ffffff;
     border-radius: 8px;
     padding: 20px;
     margin-top: calc(65px + 32px);
     margin-right: 32px;
 }

   
 @media screen and (max-width: 1073px) {
    .ourProjectPopup__container {
        margin-top: 65px;
        margin-right: 0;
    }
  }
 
 .ourProjectPopup__closebtn {
     position: absolute;
     z-index: 10;
     top: 3px;
     right: 3px;
     width: 32px;
     height: 32px;
     background-image: url(../../../images/closeBtn.png);
     background-repeat: no-repeat;
     background-position: center;
     background-size: contain;
     cursor: pointer;
     border: none;
     background-color: #fff;
     opacity: 1;
     transition: visibility 0.3s;
 }
 
 .ourProjectPopup__closebtn:hover{
   opacity: 0.3;
   transition: visibility 0.3s;
 }
 
 .ourProjectPopup__listcontainer {
     list-style-type: none;
     padding: 0;
     margin: auto;
     display: flex;
     flex-direction: column;
 }
 
 .ourProjectPopup-nav-link {
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 5px;
     padding-left: 10px;
     padding-right: 10px;
     font-family: 'Karton', sans-serif;
 }
 
 .ourProjectPopup-nav-link:hover {
     border-radius: 30px;
     background-color: #fff;
     box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
 
 }
 
 .ourProjectPopup-link {
     text-decoration: none;
     color: #FBBB00;
     border: none;
     background-color: transparent;
     font-family: 'Karton', sans-serif;
     padding: 5px;
     padding-left: 10px;
     padding-right: 10px;
     font-family: 'Karton', sans-serif;
     font-size: 16px;
 }
 
 .ourProjectPopup__subMenu {
     list-style-type: none;
     padding: 0;
     margin: 0;
     display: flex;
     margin-top: 10px;
     list-style-type: none;
     justify-content: space-between;
     width: 100%;
   }
   
   .ourProjectPopup__subMenu .burgerMainPopup-nav-link {
     padding-left: 20px;
   }
   
   .ourProjectPopup__subMenu .burgerMainPopup-link {
     color: #FBBB00;
     font-size: 14px;
   }
   
   .ourProjectPopup__subMenu .burgerMainPopup-nav-link:hover {
     background-color: #f0f0f0;
     border-radius: 10px;
   }
   
   .ourProjectPopup-nav-link button {
     cursor: pointer;
   }
 
   .ourProjectPopup-ourprojects-container {
       display: flex;
       flex-direction: column;
       align-items: flex-start;
   }
 
   .ourProjectPopup-title {
     font-family: 'Karton', sans-serif;
     font-size: 20px;
     color: #FBBB00;
   }
 
   .ourProjectPopup-subtitle {
     font-family: 'Karton', sans-serif;
     font-size: 18px;
     color: #FBBB00;
     text-align: start;
     margin-top: 0;
   }
 
   .ourProjectPopup-submenu-link_notactive {
    font-family: 'Karton', sans-serif;
    font-size: 16px;
    color: grey;
    text-align: start;
    margin: 0 0 10px 0;
   }

   .ourProjectPopup-submenu-link {
     font-family: 'Karton', sans-serif;
     font-size: 16px;
     color: #374BA5;
     text-align: start;
     margin: 0 0 10px 0;
     cursor: pointer;
     text-decoration: none;
   }
 
   .ourProjectPopup-submenu-link:hover {
     background-color: #FBBB00;
     border-radius: 30px;
     color: #fff;
     padding: 10px;
   }
 
   .ourProjectPopup-submenu-container {
      padding: 0;
      list-style-type: none;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
   }

   .ourProjectPopup-submenu-active-link {
      text-decoration: none;
   }