.reviews__section {
    width: calc(100% - 70px);
    /*padding-top: 75px;*/
    padding-left: 35px;
    padding-right: 35px;
    /*padding-bottom: 100px;*/
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;  
    justify-content: center;
}

.reviews__wrapper {
    display: grid;
    max-width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 0;
    list-style-type: none;
    margin: auto;
    margin-top: 0;
    gap: 20px
}

.reviews__title {
    font-size: 60px;
    font-family: "Mulish", sans-serif;
    font-family: 'Karton', sans-serif;
    color: #374BA5;
    display: flex;
    margin: auto;
    margin-top: 0;
    margin-bottom: 80px;
    max-width: 796px;
    z-index: 5px;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .reviews__title {
        margin-bottom: 45px;
        font-size: 48px;
        margin-bottom: 35px;
    }

    .reviews__section {
        /*padding-top: 50px;
        padding-bottom: 50px;*/
    }
}

@media screen and (max-width: 750px) {
    .reviews__title {
        font-size: 24px;
        margin-bottom: 32px;
        margin-top: 0;
    }
}


.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-btn {
    background-color: #000;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    margin: 0 10px;
}

.pagination-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination-pages {
    display: flex;
}

.pagination-page {
    background-color: transparent;
    border: 1px solid #FBBB00;
    color: #FBBB00;
    border-radius: 50%;
    margin: 0 5px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-page.active {
    background-color: #FBBB00;
    color: #fff;
}

.pagination-page:hover {
    border: 3px solid #FBBB00;
}

.reviewsSection__slider-container {
    display: flex;
}

@media screen and (max-width: 480px) {
    .pagination-page {
        width: 30px;
        height: 30px;
        font-size: 18px;
    }
}