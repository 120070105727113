.lotteryNew_container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    position: relative;
}

.lotteryNew_title_star_container {
    position: relative;

}

.lotteryNew_link {
    text-decoration: none;
    font-size: 14px;
    margin: 20px 0 20px 0;
    cursor: pointer;
    color: black;
}

.lotteryNew_link:hover {
    opacity: 0.7;
}

.lotteryNew_text_container {
    max-width: 806px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    margin: auto;
}

.lotteryNew_text_margin {
    margin-bottom: 50px !important;
}

.lotteryNew_text {
    margin: 0;
    font-family: 'Ubuntu', sans-serif;
    font-size: 24px;
    line-height: 50px;
}

.lotteryNew_text_bold {
    font-weight: bold;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.lotteryNew_text_link {
    text-decoration: none;
    color: #374BA5;
    cursor: pointer;
}

.lotteryNew_buy_btn {
    margin: auto;
    margin-bottom: 50px;
    border-radius: 20px;
    border: none;
    background-color: #EF7936;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    width: 300px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.lotteryNew_title {
    display: flex;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 25px;
    text-align: center;
    color: #374BA5;
    font-size: 28px;
    text-transform: uppercase;
    font-family: "Mulish", sans-serif;
}

.lotteryNew_star {
    background-image: url(../../../../images/hanukkah/star.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50px;
    height: 50px;
    position: absolute;
    right: -60px;
    top: -60px;
    display: flex;
}

.lotteryNew_hanukia {
    background-image: url(../../../../images/hanukkah/hanukia.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 134px;
    height: 204px;
    position: absolute;
    left: 100px;
    top: 600px;
    display: flex;
    transform: rotate(-20deg);
}
.lotteryNew_circles {
    background-image: url(../../../../images/hanukkah/circles.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 125px;
    height: 346px;
    position: absolute;
    left: 50px;
    top: 1000px;
    display: flex;
}

.lotteryNew_ordered_circles {
    background-image: url(../../../../images/hanukkah/rounds.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 400px;
    height: 400px;
    position: absolute;
    right: 200px;
    top: -20px;
    display: flex;
    transform: rotate(50deg);
}

.lotteryNew_savivon {
    background-image: url(../../../../images/hanukkah/savivon.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 122px;
    height: 450px;
    position: absolute;
    right: 150px;
    top: 400px;
    display: flex;
}


@media screen and (max-width: 1750px) {
    .lotteryNew_ordered_circles  {
        top: 0px;
        right: 100px;
        width: 350px;
        height: 350px;
    }
}

@media screen and (max-width: 1300px) {
    .lotteryNew_hanukia {
        width: 134px;
        height: 204px;
        left: 10px;
        top: 600px;
    }

    .lotteryNew_ordered_circles {
        top: -40px;
    }
}

@media screen and (max-width: 1220px) {
    .lotteryNew_savivon {
        right: 20px;
        top: 400px;
    }

    .lotteryNew_circles {
        left: 5px;
    }
}

@media screen and (max-width: 1150px) {
    .lotteryNew_ordered_circles  {
        top: -70px;
    }
}

@media screen and (max-width: 1100px) {
    .lotteryNew_hanukia {
        top: 1030px;
        width: 100px;
        height: 150px;
        left: 20px;
    }

    .lotteryNew_circles {
        top: 1430px;
        left: -15px;
        width: 100px;
        height: 300px;
    }
}

@media screen and (max-width: 1000px) {
    .lotteryNew_circles {
        left: 20px;
        top: -10px;
        transform: rotate(90deg);
    }

    .lotteryNew_ordered_circles  {
        display: none;
    }

    .lotteryNew_hanukia {
        top: 1150px;
        width: 100px;
        height: 150px;
        left: 20px;
    }
}


@media screen and (max-width: 900px) {
    .lotteryNew_savivon {
        width: 90px;
        height: 350px;
        top: 500px;
    }
}

@media screen and (max-width: 840px) {
    .lotteryNew_text {
        font-size: 18px;
        line-height: 30px;
    }

    .lotteryNew_text_container {
        max-width: 457px;
    }

    .lotteryNew_ordered_circles  {
            top: -50px;
            right: 50px;
            width: 250px;
            height: 250px;
    }

    .lotteryNew_buy_btn {
        margin-bottom: 40px;
        font-size: 18px;
        width: 300px;
        height: 50px;
    }
}

@media screen and (max-width: 700px) {
    .lotteryNew_hanukia {
        top: 1100px;
        width: 100px;
        height: 150px;
        left: 20px;
    }
}

@media screen and (max-width: 590px) {
    .lotteryNew_savivon {
        width: 73px;
        height: 263px;
        top: 550px;
        right: 0;
    }
}

@media screen and (max-width: 550px) {

    .lotteryNew_title {
        font-size: 20px;
    }

    .lotteryNew_text {
        font-size: 16px;
        line-height: 25px;
    }

    .lotteryNew_text_container {
        max-width: 312px;
    }

    .lotteryNew_ordered_circles  {
        display: none;
    }

    .lotteryNew_hanukia {
        transform: rotate(20deg);
        top: 1100px;
        width: 80px;
        height: 100px;
    }

    .lotteryNew_circles {
        left: 50px;
        top: 30px;
        transform: rotate(100deg);
        width: 70px;
        height: 150px;
    }

    .lotteryNew_rounds {
        background-image: url(../../../../images/hanukkah/circles.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 350px;
        height: 150px;
        position: absolute;
        left: 20px;
        top: -130px;
        display: flex;
        transform: rotate(70deg);
    }
}   



@media screen and (max-width: 460px) {
    .lotteryNew_savivon {
        top: 220px;
    }

    .lotteryNew_star {
        right: -20px;
    }

    .lotteryNew_text {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (max-width: 370px) {
    .lotteryNew_savivon {
        width: 60px;
        height: 200px;
        top: 50px;
    }

    .lotteryNew_hanukia {
        left: 5px;
        
        width: 80px;
        height: 100px;
    }

    .lotteryNew_star {
        right: unset;
        left: 30px;
        top: -50px;
        width: 40px;
        height: 40px;
    }
}