.section__schedule {
    background-color:#F5F5F5;
    padding-top: 80px;
}

@media screen and (max-width: 770px) {
    .section__schedule {
        padding-top: 40px;
    }
}

.section__title {
    font-weight: bold;
    font-size: 32px;
    text-align: start;
    margin-top: 0;
    margin-left: 80px;
    margin-bottom: 80px;
}

@media screen and (max-width: 1065px) {
    .section__title {
        font-size: 32px;
        margin-left: 12px;
    }
}


.section__date-container {
    max-width: 630px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

@media screen and (max-width: 770px) {
    .section__date-container {
        max-width: 300px;
    }
}

.section__date {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-size: 120px;
    margin: 0;
    border-bottom: 1px solid #D9D9D9;
    padding: 20px;
    cursor: pointer;
}

.section__date:last-of-type {
    border-bottom: none;
    margin-bottom: 277px;
}

@media screen and (max-width: 1065px) {
    .section__date {
        font-size: 100px;
        
    }
}

@media screen and (max-width: 1065px) {
    .section__date:last-of-type {
        margin-bottom: 100px;
    }
}

@media screen and (max-width: 770px) {
    .section__date {
        font-size: 50px;
    }
}

@media screen and (max-width: 770px) {
    .section__date:last-of-type {
        margin-bottom: 80px;
    }
}

.section__date:hover {
    color: #2f80ed;
}