.ourPartners__main-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 160px);
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
    position: relative;
    background-color: #fff;
}

@media screen and (max-width: 1065px) {
    .ourPartners__main-container {
        padding-left: 12px;
        padding-right: 12px;
        width: calc(100% - 24px);
    }
}

@media screen and (max-width: 770px) {
    .ourPartners__main-container {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.ourPartners__main-title {
    font-weight: bold;
    font-size: 32px;
    text-align: start;
    margin-top: 0;
    
    margin-bottom: 80px;
}

@media screen and (max-width: 1065px) {
    .ourPartners__main-title {
        font-size: 32px;
        
    }
}

@media screen and (max-width: 770px) {
    .ourPartners__main-title {
        margin-bottom: 40px;
    }
}

.ourPartners__logo-wrapper {
    list-style-type: none;
    margin: 20px;
}

.ourPartners__logo-container {
    object-fit: fill;
    text-decoration: none;
}    

.ourPartners__img {
    max-width: 200px;
    max-height: 200px;
    object-fit: fill;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .ourPartners__img {
        max-width: 150px;
        max-height: 150px;
    }
}

@media screen and (max-width: 545px) {
    .ourPartners__img {
        max-width: 100px;
        max-height: 100px;
    }
}

.ourPartners__wrapper {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    align-items: center;
    justify-content: center;
}