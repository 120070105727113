.burgerMainPopup {
   display: flex;
   justify-content: center;
   align-items: flex-start;
   margin: auto;
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   visibility: hidden;
   opacity: 0;
   height: 0;
   transition: visibility 0.3s; /*opacity 0.3s;*/
   box-sizing: border-box;
   overflow:auto;
   z-index: 10;
  }
  
.burgerMainPopup__opened {
    visibility: visible;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.3s; /*opacity 0.3s;*/
    overflow: auto;
    z-index: 10;
}

.burgerMainPopup__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    z-index: 10;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    box-sizing: border-box;
    width: 200px;
    width: 70%;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
}

.burgerMainPopup__closebtn {
    position: absolute;
    z-index: 10;
    top: 3px;
    right: 3px;
    width: 32px;
    height: 32px;
    background-image: url(../../../images/closeBtn.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    border: none;
    background-color: #fff;
    opacity: 1;
    transition: visibility 0.3s;
}

.burgerMainPopup__closebtn:hover{
  opacity: 0.3;
  transition: visibility 0.3s;
}

.burgerMainPopup__listcontainer {
    list-style-type: none;
    padding: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.burgerMainPopup-nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Karton', sans-serif;
}

.burgerMainPopup-nav-link:hover {
    border-radius: 30px;
    background-color: #FBBB00;
    /*box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);*/
}

.burgerMainPopup-link {
    text-decoration: none;
    color: #374BA5;
    border: none;
    background-color: transparent;
    font-family: 'Karton', sans-serif;
    font-family: 'Karton', sans-serif;
    font-size: 16px;
}

.burgerMainPopup-link:hover {
    color: #fff;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.burgerMainPopup__subMenu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-self: flex-start;
  }
  
  .burgerMainPopup__subMenu .burgerMainPopup-nav-link {
    padding-left: 20px;
  }
  /*
  .burgerMainPopup__subMenu .burgerMainPopup-link {
    color: #374BA5;
    font-size: 14px;
  }*/
  
  .burgerMainPopup__subMenu .burgerMainPopup-nav-link:hover {
    background-color: #f0f0f0;
    border-radius: 10px;
  }
  
  .burgerMainPopup-nav-link button {
    cursor: pointer;
  }

  .burger-main-popup-ourprojects-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }

  .burgerMainPopup-title {
    font-family: 'Karton', sans-serif;
    font-size: 20px;
    color: #FBBB00;
  }

  .burgerMainPopup-subtitle {
    font-family: 'Karton', sans-serif;
    font-size: 18px;
    color: #FBBB00;
    text-align: start;
    margin-top: 0;
  }
/*//////////////////////////////*/
  .burgerMainPopup-submenu-link_notactive {
    color: grey;
    font-family: 'Karton', sans-serif;
    font-size: 16px;
    text-align: start;
    margin: 0 0 10px 0;
  }

  .burgerMainPopup-submenu-link {
    font-family: 'Karton', sans-serif;
    font-size: 16px;
    color: #374BA5;
    text-align: start;
    cursor: pointer;
    text-decoration: none;
    margin: 0 0 10px 0;
  }

  .burgerMainPopup-submenu-link:hover {
    background-color:  #FBBB00;
    color: #fff;
    border-radius: 30px;
    padding: 10px;
  }

  .burgerMainPopup-submenu-container {
      padding: 0;
      list-style-type: none;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
  }
/*//////////////////////////////*/
  .burger-main-popup-back-arrow {
      margin-bottom: 10px;
      margin-top: 10px;
      width: 30px;
      height: 30px;
      background-image: url(../../../images/popups/arrow.png);
      background-position: center;
      background-repeat: no-repeat;
      transform: rotate(180deg);
      cursor: pointer;
      border: none;
      background-color: transparent;
  }

  .burgerMainPopup__participantSubMenu {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      list-style-type: none;
      margin: 0;
  }

  .burgerMainPopup-participant-li-wrapper {
    margin-right: 20px;
    margin-bottom: 10px;
    text-decoration: none;
    color: #374BA5;
    border: none;
    background-color: transparent;
    font-family: 'Karton', sans-serif;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }

  .burgerMainPopup-participant-li-wrapper:hover {
    border-radius: 30px;
    background-color: #FBBB00;
    color: #fff;
    padding: 5px;
    /*box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);*/
  }
