.partners__section {
    background-color: #fff;
    width: calc(100% - 86px);
    padding-left: 43px;
    padding-right: 43px;
    padding-bottom: 100px;
    padding-top: 300px;
    position: relative;
}

@media screen and (max-width: 1650px) {
    .partners__section {
        padding-top: 200px;
    }
}

@media screen and (max-width: 1000px) {
    .partners__section {
        padding-top: 150px;
    }
}

@media screen and (max-width: 635px) {
    .partners__section {
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 25px;
    }
}

@media screen and (max-width: 500px) {
    .partners__section {
        padding-top: 100px;
    }
}

.partners__page-pic {
    background-image: url(../../../images/storytime/bookpagegrey.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-width: 100%;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1650px) {
    .partners__page-pic  {
        height: 200px;
    }
}

@media screen and (max-width: 1000px) {
    .partners__page-pic  {
        height: 150px;
    }
}

@media screen and (max-width: 500px) {
    .partners__page-pic  {
        height: 100px;
    }
}

.partners__title {
    color: #374BA5;
    text-align: start;
    font-size: 42px;
    font-family: 'Karton', sans-serif;
}
@media screen and (max-width: 900px) {
    .partners__title  {
        font-size: 34px;
    }
}
@media screen and (max-width: 500px) {
    .partners__title  {
        font-size: 24px;
    }
}

.partners__logo-wrapper {
    list-style-type: none;
    margin: 20px;
}

.partners__logo-container {
    object-fit: fill;
    text-decoration: none;
}    

.partners__img {
    max-width: 200px;
    max-height: 200px;
    object-fit: fill;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .partners__img {
        max-width: 150px;
        max-height: 150px;
    }
}

@media screen and (max-width: 545px) {
    .partners__img {
        max-width: 100px;
        max-height: 100px;
    }
}

.partners__wrapper {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    align-items: center;
    justify-content: center;
}
