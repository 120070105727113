.main__footer {
    width: calc(100% - 86px);
    padding-left: 43px;
    padding-right: 43px;
    /*padding-top: 300px;*/
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;  
    padding-bottom: 100px;
}

@media screen and (max-width: 1650px) {
    .main__footer {
        /*padding-top: 200px;*/
    }
}

@media screen and (max-width: 1000px) {
    .main__footer {
        /*padding-top: 150px;*/
    }
}

@media screen and (max-width: 700px) {
    .main__footer {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}

@media screen and (max-width: 635px) {
    .main__footer {
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 25px;
    }
}

@media screen and (max-width: 500px) {
    .main__footer {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}

.main-footer-book-page {
    background-image: url(../../../images/storytime/bookpagegrey.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-width: 100%;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1650px) {
    .main-footer-book-page  {
        height: 200px;
    }
}

@media screen and (max-width: 1000px) {
    .main-footer-book-page  {
        height: 150px;
    }
}

@media screen and (max-width: 500px) {
    .main-footer-book-page  {
        height: 100px;
    }
}

.main-footer-wrapper {
    display: flex;
    justify-content: end;
    width: 100%;
}

.main-footer-contacts-contsiner {

}
.main-footer__title {
    font-family: 'Karton', sans-serif;
    font-size: 15px;
    font-weight: bold;
}
.main-footer-contacts-number {
    font-family: "Mulish", sans-serif;
    font-size: 15px;
}
.main-footer-logo {
    background-image: url(../../../images/mainPage/logobottom.png);
    background-repeat: no-repeat;
    width: 114px;
    height: 114px;
    margin-right: 24px;
    background-size: contain;
}

@media screen and (max-width: 500px) {
    .main-footer-logo {
        width: 70px;
        height: 70px;
        margin-right: 0;

    }
}

.main-footer__title {
    margin-top: 0;
    font-weight: bold;
    font-family: 'Karton', sans-serif;
    font-size: 15px;
}

.main-footer__support-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.main-footer__support-li {
    list-style-type: none;
    font-family: "Mulish", sans-serif;
    font-size: 15px;
}

@media screen and (max-width: 500px) {
    .main-footer__support-li {
        font-size: 12px;
    }
}

.main-footer__logo-support-wrapper {
    display: flex;
    z-index: 21;
    justify-content: space-between;
}

@media screen and (max-width: 500px) {
    .main-footer__logo-support-wrapper {
        width: 100%;
    }
}

.main-footer__logo-support-wrapper {
    display: flex;
    align-self: flex-end;
}

@media screen and (max-width: 500px) {
    .main-footer__logo-support-wrapper {
        flex-direction: row-reverse;
        width: 100%;
        justify-content: space-between;
    }
}

.main-footer__icons-container {
    display: flex;
    padding: 0;
    list-style-type: none;
}

.main-footer-letters-grey {
    background-image: url(../../../images/mainPage/lettersgrey.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 401px;
    height: 188px;
    position: absolute;
    left: 87px;
    bottom: 59px;
    object-fit: contain;
}

@media screen and (max-width: 1000px) {
    .main-footer-letters-grey {
        display: none;
    }
}

.main-footer__icon-facebook {
    background-image: url(../../../images/mainPage/FB.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 51px;
    height: 50px;
    margin-right: 20px;
    object-fit: contain;
}

@media screen and (max-width: 500px) {
    .main-footer__icon-facebook {
        width: 33px;
        height: 33px;
        object-fit: fill;
    }
}

.main-footer__icon-instagram {
    background-image: url(../../../images/mainPage/INSTA.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 51px;
    height: 50px;
    margin-right: 20px;
    object-fit: contain;
}

@media screen and (max-width: 500px) {
    .main-footer__icon-instagram {
        width: 33px;
        height: 33px;
        object-fit: fill;
    }
}

.main-footer__icon-tg {
    background-image: url(../../../images/mainPage/TG.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 51px;
    height: 50px;
    object-fit: contain;
}

@media screen and (max-width: 500px) {
    .main-footer__icon-tg {
        width: 33px;
        height: 33px;
    }
}

.main-footer-contacts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.main-footer__title {
    font-family: 'Karton', sans-serif;
    margin: 0;
    font-size: 20px;
    font-weight: bold;
}

@media screen and (max-width: 500px) {
    .main-footer__title {
        font-size: 16px;
    }
}

.main-footer-contacts-number {
    margin-top: 10px;
    font-size: 20px;
}

@media screen and (max-width: 500px) {
    .main-footer-contacts-number {
        font-size: 14px;
    }
}