.notReadyPage__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    margin: auto;
    min-height: 50vh;
}

.notReadyPage__title {

}

.notReadyPage__subtitle {

}

.notReadyPage__link {
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.notReadyPage__link:hover {
    opacity: 0.7;
}