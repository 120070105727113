.newAnnounce__container {
    background-image: url(../../../../images/mainPic.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 502px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding-bottom: 22px;
    width: 100%;
}

@media screen and (max-width: 1065px) {
    .newAnnounce__container {
        min-height: 319px;
    }
}

@media screen and (max-width: 766px) {
    .newAnnounce__container {
        padding-bottom: 28px;
    }
}

.newAnnounce__text-wrapper {
    margin-left: 80px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1065px) {
    .newAnnounce__text-wrapper {
        margin-left: 12px;
    }
}

.newAnnounce__title {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 40px;
    color: #fff;
    margin: 0;
    text-align: start;
    max-width: 318px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1065px) {
    .newAnnounce__title {
        font-size: 36px;
        
    }
}

@media screen and (max-width: 770px) {
    .newAnnounce__title {
        font-size: 24px;
        max-width: 220px;
    }
}

.newAnnounce__btn-container {
    display: flex;
    margin-top: 29px;
}

@media screen and (max-width: 766px) {
    .newAnnounce__btn-container {
        flex-direction: column;
    }
}

.newAnnounce__btn-link {
    background-color: #FBBB01;
    color: black;
    border-radius: 33.5px;
    text-decoration: none;
    cursor: pointer;
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-size: 20px;
    margin-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 80px 317px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.newAnnounce__btn-link:hover {
    opacity: 0.7;
}

@media screen and (max-width: 1065px) {
    .newAnnounce__btn-link {
        font-size: 14px;
    }
}

@media screen and (max-width: 766px) {
    .newAnnounce__btn-link {
        margin-right: 0px;
        margin-bottom: 10px;
    }
}

.newAnnounce__btn {
    background-color: #FF3F02;
    color: #fff;
    border-radius: 33.5px;
    text-decoration: none;
    cursor: pointer;
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-size: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.newAnnounce__btn:hover {
    opacity: 0.7;
}

@media screen and (max-width: 1065px) {
    .newAnnounce__btn {
        font-size: 14px;
    }
}