.storyTimeBannerSection__section {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.storyTimeBannerSection__pic-container {
    background-color: #EACDA5;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.storyTimeBannerSection__nav-container {
    background-color: #FCBB01;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 43px;
    padding-right: 43px;
}

@media screen and (max-width: 760px) {
    .storyTimeBannerSection__nav-container {
        padding-left: 0;
        padding-right: 0;
        flex-direction: column;
    }
}

.storyTimeBannerSection__title-container {
    font-family: 'Karton', sans-serif;
    margin-top: 61px;
}

@media screen and (max-width: 620px) {
    .storyTimeBannerSection__title-container  {
        margin-top: 40px;
    }
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__title-container  {
        margin-top: 20px;
    }
}

.storyTimeBannerSection__title {
    font-weight: 400;
    font-size: 80px;
    margin: 0;
}

@media screen and (max-width: 900px) {
    .storyTimeBannerSection__title {
        font-size: 60px;
    }
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__title {
        font-size: 50px;
    }
}

@media screen and (max-width: 440px) {
    .storyTimeBannerSection__title {
        font-size: 40px;
    }
}

@media screen and (max-width: 365px) {
    .storyTimeBannerSection__title {
        font-size: 36px;
    }
}

@media screen and (max-width: 348px) {
    .storyTimeBannerSection__title {
        font-size: 32px;
    }
}

.storyTimeBannerSection__subtitle {
    margin: 0;
    margin-bottom: 5px;
    font-size: 26px;
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__subtitle {
        font-size: 22px;
    }
}

@media screen and (max-width: 440px) {
    .storyTimeBannerSection__subtitle {
        font-size: 18px;
    }
}

@media screen and (max-width: 365px) {
    .storyTimeBannerSection__subtitle {
        font-size: 14px;
    }
}

.storyTimeBannerSection__nav-container {
    display: flex;
}

.storyTimeBannerSection__ul-container {
    display: flex;
    padding: 0;
    list-style-type: none;



}
@media screen and (max-width: 760px) {
    .storyTimeBannerSection__ul-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        width: 100%;
        margin: 0;
    }
}

.storyTimeBannerSection__left-memory-pic {
    background-image: url(../../../images/storytime/leftline.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 117px;
    height: 451px;
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__left-memory-pic {
        width: 81px;
        height: 311px;
    }
}

.storyTimeBannerSection__rigth-memory-pic {
    background-image: url(../../../images/storytime/rightline.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 84px;
    height: 451px;
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__rigth-memory-pic {
        width: 58px;
        height: 311px;
    }
}

@media screen and (mix-width: 1300px) {
    .storyTimeBannerSection__guitar-pic {
        left: 600px;
    }
}

.storyTimeBannerSection__guitar-pic {
    position: absolute;
    top: 5px;
    left: 100px;
    background-image: url(../../../images/storytime/guitar.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 127px;
    height: 217px;
    background-size: contain;
    cursor: pointer;
}

.storyTimeBannerSection__guitar-pic:hover {
    transform: rotate(30deg);
}

@media screen and (max-width: 750px) {
    .storyTimeBannerSection__guitar-pic {
        width: 107px;
        height: 200px;
        top: 70px;
        left: 70px;
    }
}

@media screen and (max-width: 670px) {
    .storyTimeBannerSection__guitar-pic {
        width: 107px;
        height: 200px;
        top: 150px;
        left: 100px;
        transform: rotate(-60deg);
    }
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__guitar-pic {
        top: 30px;
        left: 50px;
    }
}

@media screen and (max-width: 510px) {
    .storyTimeBannerSection__guitar-pic {
        width: 80px;
        height: 150px;
        top: 70px;
    }
}

.storyTimeBannerSection__envelope-pic {
    position: absolute;
    top: 267px;
    left: 200px;
    background-image: url(../../../images/storytime/envelope.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 109px;
    height: 77px;
    background-size: contain;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .storyTimeBannerSection__envelope-pic {
        left: 120px;
    }
}

@media screen and (max-width: 750px) {
    .storyTimeBannerSection__envelope-pic {
        width: 90px;
        height: 60px;
    }
}

@media screen and (max-width: 670px) {
    .storyTimeBannerSection__envelope-pic {
        left: 170px;
        top: 330px;
    }
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__envelope-pic {
        left: 120px;
        top: 195px;
    }
}

@media screen and (max-width: 510px) {
    .storyTimeBannerSection__envelope-pic {
        left: 70px;
        top: 200px;
    }
}

@media screen and (max-width: 400px) {
    .storyTimeBannerSection__envelope-pic {
        width: 70px;
        height: 50px;
    }
}

.storyTimeBannerSection__key-pic {
    position: absolute;
    top: 161px;
    left: 250px;
    background-image: url(../../../images/storytime/key.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 69px;
    height: 64px;
    background-size: contain;
    cursor: pointer;
}

@media screen and (max-width: 990px) {
    .storyTimeBannerSection__key-pic {
        left: 200px;
    }
}

@media screen and (max-width: 750px) {
    .storyTimeBannerSection__key-pic {
        width: 50px;
        height: 50px;
    }
}

@media screen and (max-width: 670px) {
    .storyTimeBannerSection__key-pic {
        top: 200px;
        transform: rotate(90deg);
        left: 230px;
    }
}
@media screen and (max-width: 600px) {
    .storyTimeBannerSection__key-pic {
        top: 150px;
        left: 200px;
    }
}

@media screen and (max-width: 560px) {
    .storyTimeBannerSection__key-pic {
        left: 180px;
    }
}

@media screen and (max-width: 510px) {
    .storyTimeBannerSection__key-pic {
        width: 30px;
        height: 40px;
        left: 140px;
        top: 125px;
        transform: rotate(20deg);
    }
}

@media screen and (max-width: 365px) {
    .storyTimeBannerSection__key-pic {
        top: 100px;
        left: 130px;
        transform: rotate(40deg);
    }
}

@media screen and (max-width: 328px) {
    .storyTimeBannerSection__key-pic {
        left: 120px;
        transform: rotate(60deg);
    }
}

@media screen and (max-width: 318px) {
    .storyTimeBannerSection__key-pic {
        top: 130px;
        left: 140px;
    }
}

.storyTimeBannerSection__book-pic {
    position: absolute;
    top: 200px;
    left: 300px;
    background-image: url(../../../images/storytime/book.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 132px;
    height: 151px;
    background-size: contain;
    cursor: pointer;
}

.storyTimeBannerSection__book-pic:hover {
    /*transform: translateY(-20px);*/
    transform: rotate(20deg);
}

@media screen and (max-width: 1000px) {
    .storyTimeBannerSection__book-pic {
        top: 250px;
        left: 250px;
    }
}

@media screen and (max-width: 750px) {
    .storyTimeBannerSection__book-pic {
        width: 110px;
        height: 130px;
    }
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__book-pic {
        width: 80px;
        height: 90px;
        top: 180px;
        left: 220px;

    }    
}

@media screen and (max-width: 510px) {
    .storyTimeBannerSection__book-pic {
        width: 70px;
        height: 80px;
        left: 150px;
        top: 170px;
        transform: rotate(40deg);
    }    
}

@media screen and (max-width: 380px) {
    .storyTimeBannerSection__book-pic {
        width: 60px;
        height: 70px;
        left: 140px;
    }    
}

@media screen and (max-width: 360px) {
    .storyTimeBannerSection__book-pic {
        top: 150px;
        left: 150px;
    }    
}

.storyTimeBannerSection__arm-pic {
    position: absolute;
    width: 80px;
    height: 103px;
    background-size: contain;
    cursor: pointer;
    top: 219px;
    left: 500px;
    background-image: url(../../../images/storytime/arm.png);
    background-repeat: no-repeat;
    background-position: center;
}

.storyTimeBannerSection__arm-pic:hover {
    /*transform: translateY(-20px);*/
    transform: rotate(50deg);
}

@media screen and (max-width: 947px) {
    .storyTimeBannerSection__arm-pic {
        left: 400px;
    }
}

@media screen and (max-width: 750px) {
    .storyTimeBannerSection__arm-pic {
        left: 350px;
    }
}

@media screen and (max-width: 670px) {
    .storyTimeBannerSection__arm-pic  {
        top: 240px;
    }
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__arm-pic  {
        width: 70px;
        height: 90px;
        top: 165px;
        left: 290px;
        transform: rotate(50deg);
    }
}

@media screen and (max-width: 504px) {
    .storyTimeBannerSection__arm-pic  {
        left: 230px;
        top: 185px;
    }
}

@media screen and (max-width: 450px) {
    .storyTimeBannerSection__arm-pic  {
        top: 130px;
    }
}

@media screen and (max-width: 430px) {
    .storyTimeBannerSection__arm-pic  {
        width: 50px;
        height: 70px;
    }
}

.storyTimeBannerSection__disk-pic {
    position: absolute;
    width: 167px;
    height: 167px;
    background-size: contain;
    cursor: pointer;
    bottom: 58px;
    left: 600px;
    background-image: url(../../../images/storytime/disk.png);
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 947px) {
    .storyTimeBannerSection__disk-pic {
        left: 500px;
    }
}

@media screen and (max-width: 800px) {
    .storyTimeBannerSection__disk-pic {
        bottom: 30px;
    }
}

@media screen and (max-width: 750px) {
    .storyTimeBannerSection__disk-pic {
        width: 100px;
        height: 100px;
    }
}

@media screen and (max-width: 680px) {
    .storyTimeBannerSection__disk-pic {
        left: 400px;
    }
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__disk-pic {
        bottom: 15px;
        left: 360px;
        width: 70px;
        height: 70px;
    }    
}

@media screen and (max-width: 510px) {
    .storyTimeBannerSection__disk-pic {
        left: 330px;
    }    
}

@media screen and (max-width: 460px) {
    .storyTimeBannerSection__disk-pic {
        left: 300px;
        width: 60px;
        height: 60px;
    }    
}

@media screen and (max-width: 415px) {
    .storyTimeBannerSection__disk-pic {
        left: 230px;
        bottom: 40px;
        width: 50px;
        height: 50px;
    }    
}

@media screen and (max-width: 380px) {
    .storyTimeBannerSection__disk-pic {
        left: 210px;
    }    
}

@media screen and (max-width: 330px) {
    .storyTimeBannerSection__disk-pic {
        left: 190px;
    }    
}

@media screen and (max-width: 320px) {
    .storyTimeBannerSection__disk-pic {
        left: 180px;
    }    
}

.storyTimeBannerSection__feather-pic {
    position: absolute;
    width: 397px;
    height: 75px;
    background-size: contain;
    cursor: pointer;
    bottom: 13px;
    left: 500px;
    background-image: url(../../../images/storytime/feather.png);
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 970px) {
    .storyTimeBannerSection__feather-pic {
        left: 400px;
    }
}

@media screen and (max-width: 890px) {
    .storyTimeBannerSection__feather-pic {
        width: 350px;
        height: 65px;
    }
}

@media screen and (max-width: 822px) {
    .storyTimeBannerSection__feather-pic {
        left: 120px;
    }
}

@media screen and (max-width: 680px) {
    .storyTimeBannerSection__feather-pic {
        width: 250px;
    }    
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__feather-pic {
        bottom: 8px;
        width: 230px;
        height: 60px;
    }    
}

@media screen and (max-width: 510px) {
    .storyTimeBannerSection__feather-pic {
        width: 200px;
        height: 50px;
        left: 90px;
    }    
}


@media screen and (max-width: 415px) {
    .storyTimeBannerSection__feather-pic {
        width: 200px;
        height: 50px;
        left: 100px;
    }    
}

@media screen and (max-width: 355px) {
    .storyTimeBannerSection__feather-pic {
        bottom: 5px;
        width: 170px;
        height: 40px;
    }    
}


.storyTimeBannerSection__savawon-pic {
    position: absolute;
    width: 56px;
    height: 69px;
    background-size: contain;
    cursor: pointer;
    top: 109px;
    right: 250px;
    background-image: url(../../../images/storytime/sovawon.png);
    background-repeat: no-repeat;
    background-position: center;
}

.storyTimeBannerSection__savawon-pic:hover {
    /*transform: translateY(-20px);*/
    transform: rotate(90deg);
}

@media screen and (max-width: 1170px) {
    .storyTimeBannerSection__savawon-pic {
        top: 30px;
    }
}

@media screen and (max-width: 973px) {
    .storyTimeBannerSection__savawon-pic {
        top: 20px;
        right: 200px;
    }
}

@media screen and (max-width: 850px) {
    .storyTimeBannerSection__savawon-pic {
        top: 5px;
        right: 150px;
    }
}

@media screen and (max-width: 750px) {
    .storyTimeBannerSection__savawon-pic {
        top: 150px;
        transform: rotate(90deg);
    }
}

@media screen and (max-width: 680px) {
    .storyTimeBannerSection__savawon-pic {
        top: 180px;
        width: 40px;
        height: 50px;
    }
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__savawon-pic {
        top: 120px;
        right: 90px;
    }
}

@media screen and (max-width: 407px) {/*380*/
    .storyTimeBannerSection__savawon-pic {
        top: 80px;
        right: 55px;
    }
}

@media screen and (max-width: 370px) {
    .storyTimeBannerSection__savawon-pic {
        width: 30px;
        height: 40px;
        right: 60px;
    }
}

.storyTimeBannerSection__compilation-pic {
    position: absolute;
    width: 119px;
    height: 94px;
    background-size: contain;
    cursor: pointer;
    top: 34px;
    right: 114px;
    background-image: url(../../../images/storytime/compilation.png);
    background-repeat: no-repeat;
    background-position: center;
}

.storyTimeBannerSection__compilation-pic:hover {
    transform: rotate(-20deg);
}

@media screen and (max-width: 973px) {
    .storyTimeBannerSection__compilation-pic {
        top: 44px;
        right: 90px;
    }
}

@media screen and (max-width: 860px) {
    .storyTimeBannerSection__compilation-pic {
        top: 60px;
        right: 80px;
        width: 100px;
        height: 84px;

    }
}

@media screen and (max-width: 750px) {
    .storyTimeBannerSection__compilation-pic {
        top: 220px;
    }
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__compilation-pic {
        width: 70px;
        height: 84px;
        top: 150px;
    }
}

@media screen and (max-width: 400px) {
    .storyTimeBannerSection__compilation-pic {
        right: 50px;
    }
}

@media screen and (max-width: 380px) {
    .storyTimeBannerSection__compilation-pic {
        width: 50px;
        height: 60px;
        /*top: 180px;*/
    }
}

@media screen and (max-width: 370px) {
    .storyTimeBannerSection__compilation-pic {
        top: 170px;
    }
}

@media screen and (max-width: 340px) {
    .storyTimeBannerSection__compilation-pic {
        transform: rotate(60deg);
        top: 180px;
    }
}


.storyTimeBannerSection__glasses-pic {
    position: absolute;

    width: 91px;
    height: 81px;
    background-size: contain;
    cursor: pointer;
    top: 208px;
    right: 114px;
    background-image: url(../../../images/storytime/glasses.png);
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 852px) {
    .storyTimeBannerSection__glasses-pic {
        top: 170px;

    }
}

@media screen and (max-width: 750px) {
    .storyTimeBannerSection__glasses-pic {
        top: 200px;
        right: 200px;
    }
}

@media screen and (max-width: 620px) {
    .storyTimeBannerSection__glasses-pic {
        top: 190px;
    }
}

@media screen and (max-width: 600px) {
    .storyTimeBannerSection__glasses-pic {
        top: 135px;
        width: 80px;
        height: 50px;
    }
}

@media screen and (max-width: 440px) {
    .storyTimeBannerSection__glasses-pic {
        top: 120px;
        right: 180px;
    }
}

@media screen and (max-width: 420px) {
    .storyTimeBannerSection__glasses-pic {
        right: 150px;
        top: 110px;
    }
}

@media screen and (max-width: 390px) {
    .storyTimeBannerSection__glasses-pic {
        right: 130px;
    }
}

@media screen and (max-width: 360px) {
    .storyTimeBannerSection__glasses-pic {
        right: 100px;
        top: 95px;
    }
}

@media screen and (max-width: 318px) {
    .storyTimeBannerSection__glasses-pic {
        right: 70px;
        top: 120px;
    }
}

.storyTimeBannerSection__picture-pic {
    position: absolute;
    width: 155px;
    height: 149px;
    background-size: contain;
    cursor: pointer;
    top: 211px;
    right: 210px;
    background-image: url(../../../images/storytime/picture.png);
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 1140px) {
    .storyTimeBannerSection__picture-pic {
        display: none;
    }
}

@media screen and (max-width: 360px) {
    .storyTimeBannerSection__picture-pic {
        display: flex;
        width: 70px;
        height: 60px;
        transform: rotate(60deg);
        top: 210px;
        right: 160px;
    }
}

@media screen and (max-width: 355px) {
    .storyTimeBannerSection__picture-pic {
        display: none;
    }
}

.storyTimeBannerSection__link {
    text-decoration: none;
    color: #fff;
}

.storyTimeBannerSection__li {
    background-color: #374BA5;
    color: #fff;
    padding: 6px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 12px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    cursor: pointer;
    font-family: 'Karton', sans-serif;
    margin-top: 14px;
    text-decoration: none;
}

.storyTimeBannerSection__li:hover {
    background-color: #F96A00;
}

@media screen and (max-width: 760px) {
    .storyTimeBannerSection__li {
        margin-right: 0;
        border-radius: 0;
        background-color: #FBBB00;
        color: #374BA5;
        font-weight: 600;
        border: 1px solid #fff;
        margin: 0;
        padding: 20px;
    }
}

@media screen and (max-width: 385px) {
    .storyTimeBannerSection__li {
        font-size: 12px;
        padding: 15px;
    }
}

.storyTimeBannerSection__link-btn {
    background-color: #F96A00;
    color: #fff;
    height: 43px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 12px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    cursor: pointer;
    font-family: 'Karton', sans-serif;
    margin-top: 14px;
    text-decoration: none;
}

.storyTimeBannerSection__link-btn:hover {
    background-color: #374BA5;
}

@media screen and (max-width: 760px) {
    .storyTimeBannerSection__link-btn {
        width: 100%;
        margin: 0;
        padding: 20px;
        border-radius: 0;
        height: fit-content;
        background-color: #374BA5;
    }
}


@media screen and (max-width: 760px) {
    .storyTimeBannerSection__link-btn:hover {
        background-color: #F96A00;
    }
}

.storyTimeBannerSection__candlestick-pic {
    display: none;
}

.storyTimeBannerSection__candlestick-pic:hover {
    transform: rotate(-50deg);
} 

@media screen and (max-width: 1800px) {
    .storyTimeBannerSection__candlestick-pic {
        display: flex;
        top: 180px;
        right: 350px;
        position: absolute;
        width: 119px;
        height: 94px;
        background-size: contain;
        cursor: pointer;
        background-image: url(../../../images/storytime/candlestick.png);
        background-repeat: no-repeat;
        background-position: center;

        transform: rotate(-50deg);
    }
}

@media screen and (max-width: 1642px) {
    .storyTimeBannerSection__candlestick-pic {
        width: 160px;
        height: 120px;
        top: 150px;
        right: 350px;
    }
}

@media screen and (max-width: 1585px) {
    .storyTimeBannerSection__candlestick-pic {
        top: 150px;
        right: 330px;
    }
}

@media screen and (max-width: 1416px) {
    .storyTimeBannerSection__candlestick-pic {
        top: 160px;

    }
}

@media screen and (max-width: 1397px) {
    .storyTimeBannerSection__candlestick-pic {
        top: 165px;
        width: 150px;
        height: 100px;
    }
}

@media screen and (max-width: 1372px) {
    .storyTimeBannerSection__candlestick-pic {
        top: 175px;
    }
}

@media screen and (max-width: 1280px) {
    .storyTimeBannerSection__candlestick-pic {
        display: none;
    }
}

.storyTimeBannerSection__suitcase-pic {
    position: absolute;
    width: 390px;
    height: 250px;
    background-size: contain;
    cursor: pointer;
    top: 200px;
    right: 440px;
    background-image: url(../../../images/storytime/suitcase.png);
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 1800px) {
    .storyTimeBannerSection__suitcase-pic {
        width: 350px;
        height: 200px;
        top: 240px;
    }
}

@media screen and (max-width: 1700px) {
    .storyTimeBannerSection__suitcase-pic {
        right: 380px;
    }
}

@media screen and (max-width: 1642px) {
    .storyTimeBannerSection__suitcase-pic {
        display: none;
    }
}

.storyTimeBannerSection__greenpicture-pic {
    position: absolute;
    width: 150px;
    height: 200px;
    background-size: contain;
    cursor: pointer;
    top: 200px;
    right: 840px;
    background-image: url(../../../images/storytime/greenpicture.png);
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 1837px) {
    .storyTimeBannerSection__greenpicture-pic{
        width: 100px;
        height: 100px;
        top: 250px;
    }
}

@media screen and (max-width: 1750px) {
    .storyTimeBannerSection__greenpicture-pic{
        right: 760px;
        width: 80px;
        height: 70px;
        
    }
}

@media screen and (max-width: 1642px) {
    .storyTimeBannerSection__greenpicture-pic {
        display: none;
    }
}

.storyTimeBannerSection__secondarm-pic {
    display: none;
}

.storyTimeBannerSection__secondarm-pic:hover {
    transform: rotate(0);
}

@media screen and (max-width: 1642px) {
    .storyTimeBannerSection__secondarm-pic {
        position: absolute;
        width: 180px;
        height: 203px;
        background-size: contain;
        cursor: pointer;
        top: 219px;
        right: 500px;
        background-image: url(../../../images/storytime/arm.png);
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(60deg);
        display: flex;
    }
}

@media screen and (max-width: 1624px) {
    .storyTimeBannerSection__secondarm-pic {
        width: 150px;
        height: 170px;
    }
}

@media screen and (max-width: 1585px) {
    .storyTimeBannerSection__secondarm-pic {
        width: 130px;
        height: 150px;
        right: 450px;
    }
}

@media screen and (max-width: 1400px) {
    .storyTimeBannerSection__secondarm-pic {
        width: 110px;
        height: 120px;
    }
}

@media screen and (max-width: 1385px) {
    .storyTimeBannerSection__secondarm-pic {
        top: 240px;
        right: 400px;
    }
}

@media screen and (max-width: 1335px) {
    .storyTimeBannerSection__secondarm-pic {
        top: 270px;
        width: 90px;
        height: 100px;
    }
}

@media screen and (max-width: 1314px) {
    .storyTimeBannerSection__secondarm-pic {
        top: 290px;
        right: 370px;
    }
}

@media screen and (max-width: 1284px) {
    .storyTimeBannerSection__secondarm-pic {
        display: none;
    }
}